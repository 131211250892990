import React, { Component } from 'react';
import host from "../../config/api";
import MediaQuery from "react-responsive";
import Carousel from "bee-carousel";

export class Atouts extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hovered: []  // Use an array to track the hover state of each item
    };
  }
  handleMouseEnter = (index) => {
    this.setState((prevState) => {
      const hovered = [...prevState.hovered];
      hovered[index] = true;  // Set hover state for the specific item
      return { hovered };
    });
  };

  handleMouseLeave = (index) => {
    this.setState((prevState) => {
      const hovered = [...prevState.hovered];
      hovered[index] = false;  // Remove hover state for the specific item
      return { hovered };
    });
  };

  render() {
    const { project } = this.props;

    if (project && project.nosAtout && project.nosAtout.length > 0) {
      const params = {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        slidesPerView: 1.4,
        spaceBetween: 10,
      };

      return (
        <div className="container">
          <MediaQuery maxDeviceWidth={790}>
            {/* Mobile */}
            <Carousel {...params}>
              {project.nosAtout.map((item, index) => (
                <div key={index} className="swiper-slide video">
                  <div className="card" style={{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.70), rgba(255, 255, 255, 0.70)), url(${host + 'uploads/' + item.image})` }}>
                    <div className="flex-items">
                      <img src={host + 'uploads/' + item.icon} className="icon" alt={item.icon} />
                      <h3>{item.title}</h3>
                    </div>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </MediaQuery>

          <MediaQuery minDeviceWidth={790}>
            {/* Desktop */}
            <div className="row video">
              {project.nosAtout.map((item, index) => (
                <div key={index} className="col-md-6">
                  <div className={`card ${this.state.hovered[index] ? "" : "linear"}`}
                    onMouseEnter={() => this.handleMouseEnter(index)}  // Pass index to track which card is hovered
                    onMouseLeave={() => this.handleMouseLeave(index)}  // Pass index to track which card is hovered
                  style={{ backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.5), rgba(128, 128, 128, 0.5)), url(${host + 'uploads/' + item.image})` }}>
                    <div className="flex-items">
                      <img src={host + 'uploads/' + item.icon} className="icon" alt={item.icon} />
                      <h3>{item.title}</h3>
                    </div>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </MediaQuery>
        </div>
      );
    } else {
      return <div>No project data available.</div>;
    }
  }
}

export default Atouts;
