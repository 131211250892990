import React, { Component } from "react";
import host from '../../config/api';

export class Acceuil extends Component {
  render() {
    const { project } = this.props;

    if (!project) {
      return null;
    }

    return (
      <div 
        style={{ 
          backgroundImage: `url(${host + "uploads/" + project.cover})`,
          height: "650px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      >
      </div>
    );
  }
}

export default Acceuil;
