import React, { Component } from "react";
// import calendar from "../../Images/calendar.png";
// import quality from "../../Images/quality.png";
// import like from "../../Images/like.png";
// import transparency from "../../Images/transparency.png";
import Logo from "../../Images/logochaabane.png";
// import { useNavigate } from "react-router-dom";
// import Countries from "../websitelayout/frCountries";
import Projects from "../websitelayout/projects.json";
import CountriesService from "../../services/countries-service";
import Countries from "../websitelayout/frCountriesNew";
import { Dropdown } from "primereact/dropdown";

export class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.countriesService = new CountriesService();
    // this.state = {
    //   contact: {
    //     gender: "",
    //     firstName: "",
    //     lastName: "",
    //     email: "",
    //     project: "",
    //     message: "",
    //     country: {},
    //     phonePrefix: "",
    //     phoneNumber: "",
    //     refer: "",
    //     condition: false,
    //   },

    //   Countries: [],
    //   selectedCountry: {},
    //   code: "",
    // };

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      project: "",
      message: "",
      country: "Maroc",
      phonePrefix: "+212",
      phoneNumber: "",
      refer: "",
      projectRef: "",
      condition: false,
      messageError: "",
      Countries: [],
      selectedCountry: {},
      code: "",
    };

    this.handleMessage = this.handleMessage.bind(this);
    this.handleRefer = this.handleRefer.bind(this);
    this.handleProject = this.handleProject.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    // this.handlePrefix = this.handlePrefix.bind(this);
    // this.handleCountry = this.handleCountry.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleLastName = this.handleLastName.bind(this);
    this.handleFirstName = this.handleFirstName.bind(this);
    // this.handleGender = this.handleGender.bind(this);
    this.handleCondition = this.handleCondition.bind(this);
  }
  async componentDidMount() {
    // this.setState({ contact: { phonePrefix: "+212" } });

    let arrayCountries = [];

    await this.countriesService.getIpAdress().then((code_) => {
      this.setState({
        code: code_,
      });
      console.log("ip", code_);
    });

    Countries.forEach((data) => {
      if (data.alpha2Code === this.state.code) {
        console.log("dooooone", this.state.code);
        this.setState({
          selectedCountry: {
            name: data.name,
            dial_code: "+" + data.CallCodes[0],
            code: data.alpha2Code,
            flag: data.flag,
          },
        });
      }
      const dataa = {
        name: data.name,
        dial_code: "+" + data.CallCodes[0],
        code: data.alpha2Code,
        flag: data.flag,
      };
      arrayCountries.push(dataa);
    });

    console.log("arrayCountries", arrayCountries);

    this.setState({ Countries: arrayCountries });
  }

  // handleMessage(event) {
  //   this.setState({ contact: { message: event.target.value } });
  // }
  // handleRefer(event) {
  //   this.setState({ contact: { refer: event.target.value } });
  // }
  // handleProject(event) {
  //   this.setState({ contact: { project: event.target.value } });
  // }
  // handlePhone(event) {
  //   this.setState({ contact: { phoneNumber: event.target.value } });
  // }
  // handlePrefix(event) {
  //   this.setState({ contact: { phonePrefix: event.target.value } });
  // }
  // handleCountry(event) {
  //   this.setState({ contact: { country: event.target.value } });

  //   Countries.map((option) => {
  //     if (option.name === event.target.value) {
  //       this.setState({ contact: { phonePrefix: option.dial_code } });
  //     }
  //   });
  // }

  selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          {/* <img
            alt={option.name}
            src={option.flag}
            onError={(e) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            className={`flag flag-${option.code.toLowerCase()}`}
          /> */}
          <div>{" (" + option.dial_code + ")"}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        {/* <img
          alt={option.name}
          src={option.flag}
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          className={`flag flag-${option.code.toLowerCase()}`}
        /> */}
        <div>{option.name + " (" + option.dial_code + ")"}</div>
      </div>
    );
  };

  setSelectedCountry(value) {
    console.log("value", value);

    this.setState({ country: value.name });
    this.setState({ phonePrefix: value.dial_code });
    this.setState({
      selectedCountry: value,
    });

    console.log(this.state.country + " " + this.state.phonePrefix);
  }

  handleMessage(event) {
    this.setState({ message: event.target.value });
  }
  handleRefer(event) {
    this.setState({ refer: event.target.value });
  }
  handleProject(event) {
    this.setState({ projectRef: event.target.value });
  }
  handlePhone(event) {
    this.setState({ phoneNumber: event.target.value });
  }
  // handlePrefix(event) {
  //   this.setState({ phonePrefix: event.target.value });
  // }
  // handleCountry(event) {
  //   this.setState({ country: event.target.value });

  //   Countries.map((option) => {
  //     if (option.name === event.target.value) {
  //       this.setState({ phonePrefix: option.dial_code });
  //     }
  //   });
  // }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleLastName(event) {
    this.setState({ lastName: event.target.value });
  }
  handleFirstName(event) {
    this.setState({ firstName: event.target.value });
  }
  handleCondition(event) {
    this.setState({ condition: event.target.checked });
  }

  async submit(event) {
    event.preventDefault();

    if (!this.state.lastName || this.state.lastName === "") {
      this.setState({ messageError: "Veuillez saisir votre nom" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.firstName || this.state.firstName === "") {
      this.setState({ messageError: "Veuillez saisir votre prénom" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.email || this.state.email === "") {
      this.setState({ messageError: "Veuillez saisir votre email" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      this.setState({
        messageError: "Veuillez saisir une adresse email valide",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    if (!this.state.country || this.state.country === "") {
      this.setState({ messageError: "Veuillez choisir votre pays" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.phonePrefix || this.state.phonePrefix === "") {
      this.setState({
        messageError: "Veuillez choisir l'indicateur de votre pays",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    if (!this.state.phoneNumber || this.state.phoneNumber === "") {
      this.setState({ messageError: "Veuillez saisir un numéro de téléphone" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.projectRef || this.state.projectRef === "") {
      this.setState({ messageError: "Veuillez sélectionez un projet" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    } else {
      await Projects.forEach((project) => {
        if (project.ref === this.state.projectRef) {
          this.setState({ project: project.title });
        }
      });
    }

    if (!this.state.refer || this.state.refer === "") {
      this.setState({ messageError: "Comment vous nous avez connus ?" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.condition || this.state.condition === false) {
      this.setState({
        messageError:
          "Veuillez accepter les conditions générales d'utilisation",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    const infoProspect = {};
    infoProspect.state = this.state;
    infoProspect.state.Countries = undefined;
    infoProspect.state.code = undefined;
    infoProspect.state.selectedCountry = undefined;

    if (sessionStorage.getItem("utms")) {
      infoProspect.utms = JSON.parse(sessionStorage.getItem("utms"));
    }
    // else if (sessionStorage.getItem("refer")) {
    //   infoProspect.refer = sessionStorage.getItem("refer");
    // }

    localStorage.setItem("prospect", JSON.stringify(infoProspect));

    /* await this.EmailService.sendEmail(body).then(data => {
          console.log('email', data);
      });*/
    // this.props.history.push("/remerciements");
    window.location.href = "/remerciements";
  }

  // handleEmail(event) {
  //   this.setState({ contact: { email: event.target.value } });
  // }
  // handleLastName(event) {
  //   this.setState({ contact: { lastName: event.target.value } });
  // }
  // handleFirstName(event) {
  //   this.setState({ contact: { firstName: event.target.value } });
  // }
  // handleGender(event) {
  //   this.setState({ contact: { gender: event.target.value } });
  // }
  // handleCondition(event) {
  //   this.setState({ contact: { condition: event.target.checked } });
  // }
  // renderOption() {
  //   return Countries.map((option) =>
  //     option.name === "Maroc" ? (
  //       <option key={option.dial_code} selected value={option.name}>
  //         {option.name}
  //       </option>
  //     ) : (
  //       <option key={option.dial_code} value={option.name}>
  //         {option.name}
  //       </option>
  //     )
  //   );
  // }

  render() {
    return (
      <div
        id="engagements-section"
        className="container animated animatedFadeInUp fadeInUp"
      >
        <div className="row align-items-center" id="contact-section">
          <div className="col-md-7" id="form-sec">
            <form action="/remerciements">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control inpt"
                  id="nom"
                  placeholder="Nom*"
                  value={this.state.lastName}
                  onChange={this.handleLastName}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control inpt"
                  id="prenom"
                  placeholder="Prénom*"
                  value={this.state.firstName}
                  onChange={this.handleFirstName}
                  required
                />
              </div>
              <div className="form-row">
                {/* <div className="form-group col-md-4">
                  <select
                    id="inputCountry"
                    className="form-control inpt"
                    onChange={this.handleCountry}
                  >
                    {this.renderOption()}
                  </select>
                </div>
                <div className="form-group col-md-2">
                  <input
                    type="text"
                    className="form-control inpt"
                    id="inputPhoneCode"
                    placeholder="+212*"
                    value={this.state.phonePrefix}
                    required
                  />
                </div> */}

                <div className="form-group d-flex w-100 contact-form-drop">
                  <Dropdown
                    value={this.state.selectedCountry}
                    options={this.state.Countries}
                    onChange={(e) => {
                      this.setSelectedCountry(e.value);
                    }}
                    optionLabel="name"
                    filter
                    showClear
                    filterBy="name"
                    placeholder="Select a Country"
                    className="contact-dropdown"
                    valueTemplate={this.selectedCountryTemplate}
                    itemTemplate={this.countryOptionTemplate}
                  />
                  <input
                    type="number"
                    className="form-control inpt w-100"
                    id="inputPhone"
                    placeholder="Tel*"
                    value={this.state.phoneNumber}
                    onChange={this.handlePhone}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control inpt"
                  id="exampleInputEmail1"
                  placeholder="email@exemple.com*"
                  value={this.state.email}
                  onChange={this.handleEmail}
                  required
                />
              </div>
              <div className="form-group">
                <select
                  id="inputCountry"
                  className="form-control inpt"
                  onChange={this.handleProject}
                  value={this.state.projectRef}
                >
                  <option value="">Veuillez choisir votre projet*</option>
                  <option value="CH28474">ANFA BLOOM</option>
                  <option value="CH28473">Le 16 eme Angle</option>
                  <option value="CH28471">The Sand House</option>
                  <option value="CH28465">Blue Waters House</option>
                  <option value="CH28472">Magic House Beach</option>
                  <option value="CH28468">Les Perles de Bentriaa II</option>
                  <option value="CH28469">Magic House</option>
                  <option value="CH28467">Garden Bay</option>
                  <option value="CH28470">Ricoflores Palm</option>
                </select>
              </div>
              <div className="form-group">
                <select
                  id="inputCountry"
                  className="form-control inpt"
                  onChange={this.handleRefer}
                  required
                >
                  <option value="">Comment vous nous avez connus ?*</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Bannière web">Bannière web</option>
                  <option value="Google">Google</option>
                  <option value="YouTube">YouTube</option>
                  <option value="Gmail">Gmail</option>
                  <option value="Mubawab">Mubawab</option>
                  <option value="Parrainage client">Parrainage client</option>
                  <option value="Recommandé par autre">
                    Recommandé par autre
                  </option>
                  <option value="Bureau de vente">Bureau de vente</option>
                  <option value="Habillage véhicule">Habillage véhicule</option>
                  <option value="Brochure / flyer publicitaire">
                    Brochure / flyer publicitaire
                  </option>
                  <option value="Panneaux Publicitaires">
                    Panneaux Publicitaires
                  </option>
                  <option value="Palissade">Palissade</option>
                  <option value="Panneaux signalétiques">
                    Panneaux signalétiques
                  </option>
                  <option value="La Fondation Mohamed VI">
                    La Fondation Mohamed VI
                  </option>
                  <option value="Wana Corporate">Wana Corporate</option>
                  <option value="Influenceur/blogueur">
                    Influenceur/blogueur
                  </option>
                  <option value="Waze">Waze</option>
                </select>
              </div>
              <div className="mb-3">
                <textarea
                  className="form-control inpt"
                  id="validationTextarea"
                  placeholder="Message"
                  value={this.state.message}
                  onChange={this.handleMessage}
                ></textarea>

                {this.state.messageError !== "" ? (
                  <div style={{ marginTop: "10px" }} className="alert alert-danger">
                    <strong>{this.state.messageError}</strong>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="row align-items-end">
                <div className="col"></div>
                <div className="col"></div>
                <div className="col"></div>
                <button
                  id="btn"
                  onClick={this.submit.bind(this)}
                  className="col btn btn-primary"
                >
                  Envoyer
                </button>
              </div>
              <br />
            </form>
          </div>
          <div className="col-md-5" id="logo-sec">
            <img id="image" src={Logo} alt="" />
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input chkinpt"
                id="exampleCheck1"
                checked={this.state.condition}
                onChange={this.handleCondition}
                required
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                J'ai lu et j'accepte{" "}
                <a
                  style={{ color: "#b5a282" }}
                  href="/conditions-generales"
                  target="_blank"
                >
                  les conditions générales d'utilisation{" "}
                </a>
                notamment la mention relative à{" "}
                <a
                  style={{ color: "#b5a282" }}
                  href="/politique-de-confidentialite"
                  target="_blank"
                >
                  la protection des données personnelles
                </a>
                .
              </label>
            </div>
            <p>
              Conformément à la loi 09-08, vous disposez d'un droit d'accès, de
              rectification et d'opposition au traitement de vos données
              personnelles. Ce traitement à été notifié à la CNDP au titre du
              récépissé n°...
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
