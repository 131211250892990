import React, { Component } from "react";
import host from "../../config/api";
import MediaQuery from "react-responsive";

export class Description extends Component {
  render() {
    const { project } = this.props;

    if (project && project.description) {
      const { description } = project;

      return (
        <div>
          <MediaQuery maxDeviceWidth={790}>
            {/* Mobile */}
            <div className="mobile-desc">
              <div className="row align-items-center" style={{ margin: "30px" }}>
                <div className="col-md-6">
                  <h1 style={{ width: "fit-content" }}>{description.title}</h1>
                  <h4 style={{ fontFamily: "montserrat", fontStyle: "italic", fontSize: "20px" }}>
                    {description.subTitle}
                  </h4>
                  <div style={{ textAlign: "justify", textJustify: "inter-word" }} dangerouslySetInnerHTML={{ __html: description.text }} />
                  <div id="notregroupe-image" className="col-md-6">
                    <img src={`${host}uploads/${description.image}`} alt={description.title} />
                  </div>
                  <a className="btn" target="_blank" rel="noopener noreferrer" href={`${host}uploads/${description.brochure}`} style={{ margin: "52px auto 0", display: "block", width: "fit-content", justifyContent: "center" }} download>
                    <i className="fa fa-download"></i> Télécharger la brochure
                  </a>
                </div>
              </div>
            </div>
          </MediaQuery>

          <MediaQuery minDeviceWidth={790}>
            {/* Desktop */}
            <div className="row align-items-center" style={{ margin: "30px" }}>
              <div className="col-md-6">
                <h1 style={{ width: "fit-content" }}>{description.title}</h1>
                <h4 style={{ fontFamily: "montserrat", fontStyle: "italic", fontSize: "20px" }}>
                  {description.subTitle}
                </h4>
                <p style={{ textAlign: "justify", textJustify: "inter-word" }} dangerouslySetInnerHTML={{ __html: description.text }} />
                <a className="btn" target="_blank" rel="noopener noreferrer" href={`${host}uploads/${description.brochure}`} style={{ margin: "0 auto", display: "block", width: "fit-content", justifyContent: "center" }} download>
                  <i className="fa fa-download"></i> Télécharger la brochure
                </a>
              </div>
              <div id="notregroupe-image" className="col-md-6">
                <img src={`${host}uploads/${description.image}`} alt={description.title} />
              </div>
            </div>
          </MediaQuery>
        </div>
      );
    } else {
      return <div>Loading...</div>; // Or any fallback UI
    }
  }
}

export default Description;
