import React, { Component } from "react";
// import Logo from "../../Images/logochaabane.png";
// import backgroundtsh from "../../Images/footer-tsh.jpg";
// import backgroundrp from "../../Images/footer-rp.png";
// import backgroundmh from "../../Images/footer-mh.png";
// import backgroundgb from "../../Images/footer-gb.jpg";
// import backgroundbwh from "../../Images/footer-lpp.png";
// import backgroundlpdb from "../../Images/footer-lpb.png";
import 'primeicons/primeicons.css';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";

import "./Contact.css";
import image from "../../Images/contact.jpg";
// import { useNavigate } from "react-router-dom";
// import host from "../../config/api";
import ProjectService from "../../services/project-service";
import Countries from "../websitelayout/frCountriesNew";
import Projects from "./projects";
import $ from "jquery";
import CountriesService from "../../services/countries-service";

import { Dropdown } from "primereact/dropdown";

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.projectService = new ProjectService();
    this.countriesService = new CountriesService();
    this.state = {
      static: true,
      firstName: "",
      lastName: "",
      email: "",
      project: "",
      message: "",
      country: "Maroc",
      phonePrefix: "+212",
      phoneNumber: "",
      projectRef: "",
      refer: "",
      condition: false,
      messageError: "",
      Countries: [],
      selectedCountry: {},
      code: "",
    };
    this.handleMessage = this.handleMessage.bind(this);
    this.handleRefer = this.handleRefer.bind(this);
    this.handleProject = this.handleProject.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handlePrefix = this.handlePrefix.bind(this);
    // this.handleCountry = this.handleCountry.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleLastName = this.handleLastName.bind(this);
    this.handleFirstName = this.handleFirstName.bind(this);
    //  this.handleGender = this.handleGender.bind(this);
    this.handleCondition = this.handleCondition.bind(this);
    this.getReference = this.getReference.bind(this);
  }
  async componentDidMount() {
    // this.setState({ phonePrefix: '+212' });
    // this.setState({ country: 'Maroc' });
    if ($("body").hasClass("magic-house")) {
      this.setState({ projectRef: "CH28469" });
    } else if ($("body").hasClass("garden-bay")) {
      this.setState({ projectRef: "CH28467" });
    } else if ($("body").hasClass("ricoflor-palmes")) {
      this.setState({ projectRef: "CH28470" });
    } else if ($("body").hasClass("the-sand-house")) {
      this.setState({ projectRef: "CH28471" });
    } else if ($("body").hasClass("blue-waters-house")) {
      this.setState({ projectRef: "CH28465" });
    } else if ($("body").hasClass("les-perles-de-bentriaa")) {
      this.setState({ projectRef: "CH28468" });
    } else if ($("body").hasClass("magic-house-beach")) {
      this.setState({ projectRef: "CH28472" });
    }else if ($("body").hasClass("_16eme-angle")) {
      this.setState({ projectRef: "CH28473" });
    }else if ($("body").hasClass("anfabloom")) {
      this.setState({ projectRef: "CH28474" });
    } else {
      this.setState({ projectRef: "" });
    }

    let arrayCountries = [];

    this.countriesService.getIpAdress().then((code_) => {
      this.setState({
        code: code_,
      });
    });

    Countries.forEach((data) => {
      if (data.alpha2Code === this.state.code) {
        this.setState({
          selectedCountry: {
            name: data.name,
            dial_code: "+" + data.CallCodes[0],
            code: data.alpha2Code,
            flag: data.flag,
          },
        });
      }else {
        this.setState({
          selectedCountry: {
            name: "Maroc",
            dial_code: "+212",
            code: "MA",
            flag: "https://restcountries.eu/data/mar.svg",
          },
        });
      }
      const dataa = {
        name: data.name,
        dial_code: "+" + data.CallCodes[0],
        code: data.alpha2Code,
        flag: data.flag,
      };
      arrayCountries.push(dataa);
    });

    this.setState({ Countries: arrayCountries });
  }
  handleMessage(event) {
    this.setState({ message: event.target.value });
  }
  handleRefer(event) {
    this.setState({ refer: event.target.value });
  }
  handleProject(event) {
    this.setState({ projectRef: event.target.value });
  }
  handlePhone(event) {
    this.setState({ phoneNumber: event.target.value });
  }
  handlePrefix(event) {
    this.setState({ phonePrefix: event.target.value });
  }
  // handleCountry(event) {
  //   this.setState({ country: event.target.value });
  //   Countries.map((option) => {
  //     if (option.name === event.target.value) {
  //       this.setState({ phonePrefix: option.dial_code });
  //     }
  //   });
  // }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleLastName(event) {
    this.setState({ lastName: event.target.value });
  }
  handleFirstName(event) {
    this.setState({ firstName: event.target.value });
  }
  handleCondition(event) {
    this.setState({ condition: event.target.checked });
  }

  selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          {/* <img
            alt={option.name}
            src={option.flag}
            // onError={(e) =>
            //   (e.target.src =
            //     "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            // }
            className={`flag flag-${option.code.toLowerCase()}`}
          /> */}
          <div>{"(" + option.dial_code + ")"}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        {/* <img
          alt={option.name}
          src={option.flag}
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          className={`flag flag-${option.code.toLowerCase()}`}
        /> */}
        <div>{option.name + " (" + option.dial_code + ")"}</div>
      </div>
    );
  };

  setSelectedCountry(value) {
    this.setState({ country: value.name });
    this.setState({ phonePrefix: value.dial_code });
    this.setState({
      selectedCountry: value,
    });
  }

  // renderOption() {
  //   return Countries.map((option) =>
  //     option.name === "Maroc" ? (
  //       <option key={option.dial_code} selected value={option.name}>
  //         {option.name}
  //       </option>
  //     ) : (
  //       <option key={option.dial_code} value={option.name}>
  //         {option.name}
  //       </option>
  //     )
  //   );
  // }

  getReference() {
    console.log("hna");
    if ($("body").hasClass("magic-house")) {
      this.setState({ projectRef: "CH28469" });
      return "CH28469";
    } else if ($("body").hasClass("garden-bay")) {
      this.setState({ projectRef: "CH28467" });
      return "CH28467";
    } else if ($("body").hasClass("ricoflor-palmes")) {
      this.setState({ projectRef: "CH28470" });
      return "CH28470";
    } else if ($("body").hasClass("the-sand-house")) {
      this.setState({ projectRef: "CH28471" });
      return "CH28471";
    } else if ($("body").hasClass("blue-waters-house")) {
      this.setState({ projectRef: "CH28465" });
      return "CH28465";
    } else if ($("body").hasClass("les-perles-de-bentriaa")) {
      this.setState({ projectRef: "CH28468" });
      return "CH28468";
    } else if ($("body").hasClass("magic-house-beach")) {
      console.log("class =>", true);
      this.setState({ projectRef: "CH28472" });
      return "CH28472";
    }else if ($("body").hasClass("_16eme-angle")) {
      console.log("class =>", true);
      this.setState({ projectRef: "CH28473" });
      return "CH28473";
    }else if ($("body").hasClass("anfabloom")) {
      console.log("class =>", true);
      this.setState({ projectRef: "CH28474" });
      return "CH28474";
    } else {
      return "";
    }
  }
  renderOptionProjects() {
    if (Projects){
      const currentUrl = window.location.href;
      console.log("currentUrl",currentUrl);

      const parts = currentUrl.split('/');
      const lastPart = parts[parts.length - 1];
      console.log("parts",parts);
      console.log("lastPart",lastPart);

      let projectRef = "";
      if (lastPart === "magic-house") {
        projectRef = "CH28469";
      } else if (lastPart === "garden-bay") {
        projectRef = "CH28467";
      } else if (lastPart === "ricoflores-palm") {
        projectRef = "CH28470";
      } else if (lastPart === "the-sand-house") {
        projectRef = "CH28471";
      } else if (lastPart === "blue-waters-house") {
        projectRef = "CH28465";
      } else if (lastPart === "les-perles-de-bentriaa") {
        projectRef = "CH28468";
      } else if (lastPart === "magic-house-beach") {
        projectRef = "CH28472";
      }else if (lastPart === "16eme-angle") {
        projectRef = "CH28473";
      }else if (lastPart === "AnfaBloom") {
        projectRef = "CH28474";
      }
      console.log("projectRef",projectRef)
      return Projects.map((option) => (
        <option key={option.ref} value={option.ref} selected={option.ref === projectRef}>{option.title}</option>
      ));
    }
  }

  async send(event) {
    event.preventDefault();

    if (!this.state.lastName || this.state.lastName === "") {
      this.setState({ messageError: "Veuillez saisir votre nom" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.firstName || this.state.firstName === "") {
      this.setState({ messageError: "Veuillez saisir votre prénom" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.country || this.state.country === "") {
      this.setState({ messageError: "Veuillez choisir votre pays" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.phonePrefix || this.state.phonePrefix === "") {
      this.setState({
        messageError: "Veuillez choisir l'indicateur de votre pays",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    if (!this.state.phoneNumber || this.state.phoneNumber === "") {
      this.setState({ messageError: "Veuillez saisir un numéro de téléphone" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.email || this.state.email === "") {
      this.setState({ messageError: "Veuillez saisir votre email" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      this.setState({
        messageError: "Veuillez saisir une adresse email valide",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    if (!this.state.projectRef || this.state.projectRef === "") {
      this.setState({ messageError: "Veuillez sélectionez un projet project" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    } else {
      await Projects.forEach((project) => {
        if (project.ref === this.state.projectRef) {
          this.setState({ project: project.title });
        }
      });
    }

    if (!this.state.refer || this.state.refer === "") {
      this.setState({ messageError: "Comment vous nous avez connus ?" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.condition || this.state.condition === false) {
      this.setState({
        messageError:
          "Veuillez accepter les conditions générales d'utilisation",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    const infoProspect = {};
    infoProspect.state = this.state;
    infoProspect.state.Countries = undefined;
    infoProspect.state.code = undefined;
    infoProspect.state.selectedCountry = undefined;
    if (sessionStorage.getItem("utms")) {
      infoProspect.utms = JSON.parse(sessionStorage.getItem("utms"));
    }
    // else if (sessionStorage.getItem("refer")) {
    //   infoProspect.refer = sessionStorage.getItem("refer");
    // }

    localStorage.setItem("prospect", JSON.stringify(infoProspect));
    this.props.history.push("/remerciements");
  }

  render() {
    if (this.state.project) {
      return (
        <div
          id="Contact"
          className="footer-contact"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="container">
            <div className="row align-items-center" id="contact-section">
              <div className="footer-contact-bloc">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="footer-contact-desc">
                        <div className="footer-contact-desc-contect">
                          <h3 className="footer-contact-desc-titre">
                            Nous contacter
                          </h3>
                          <div className="footer-contact-desc-text">
                            <p>
                              N’hésitez pas à remplir notre formulaire pour que
                              l’un de nos conseillers puisse vous renseigner
                              davantage.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label className="footer-cotect-label">
                          Nom de famille*
                        </label>
                        <input
                          type="text"
                          className="form-control inpt"
                          id="nom"
                          placeholder="Nom*"
                          value={this.state.lastName}
                          onChange={this.handleLastName}
                        />
                      </div>
                      <div className="form-group">
                        <label className="footer-cotect-label">Prénom*</label>
                        <input
                          type="text"
                          className="form-control inpt"
                          id="prenom"
                          placeholder="Prénom*"
                          value={this.state.firstName}
                          onChange={this.handleFirstName}
                        />
                      </div>
                      <div className="form-group">
                        <label className="footer-cotect-label">Email*</label>
                        <input
                          type="email"
                          className="form-control inpt"
                          id="exampleInputEmail1"
                          placeholder="email@exemple.com*"
                          value={this.state.email}
                          onChange={this.handleEmail}
                        />
                      </div>
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input chkinpt"
                          id="exampleCheck1"
                          checked={this.state.condition}
                          onChange={this.handleCondition}
                        />
                        <label className="form-check-label" htmlFor="exampleCheck1">
                          J'ai lu et j'accepte{" "}
                          <a
                            style={{ color: "#b5a282" }}
                            href="/conditions-generales"
                            target="_blank"
                          >
                            les conditions générales d'utilisation{" "}
                          </a>{" "}
                          notamment la mention relative à{" "}
                          <a
                            style={{ color: "#b5a282" }}
                            href="/politique-de-confidentialite"
                            target="_blank"
                          >
                            la protection des données personnelles
                          </a>
                          .
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-row">
                        <div className="form-group w-100">
                          <label className="footer-cotect-label">Téléphone*</label>
                          <div className="phone-field">
                            {/* <select
                              id="inputCountry"
                              className="form-control inpt"
                              onChange={this.handleCountry}
                            >
                              {this.renderOption()}
                            </select>
                            <input
                              type="text"
                              className="form-control inpt country-code"
                              id="inputPhoneCode"
                              placeholder="+212*"
                              value={this.state.phonePrefix}
                            /> */}
                            <Dropdown
                              value={this.state.selectedCountry}
                              options={this.state.Countries}
                              onChange={(e) => {
                                this.setSelectedCountry(e.value);
                              }}
                              optionLabel="name"
                              filter
                              showClear
                              filterBy="name"
                              placeholder="Select a Country"
                              valueTemplate={this.selectedCountryTemplate}
                              itemTemplate={this.countryOptionTemplate}
                            />
                            <input
                              type="number"
                              className="form-control inpt w-100"
                              id="inputPhone"
                              placeholder="Tel*"
                              value={this.state.phoneNumber}
                              onChange={this.handlePhone}
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div className="form-group">
                        <Dropdown
                          value={this.state.country}
                          options={this.state.Countries}
                          onChange={(e) => this.setSelectedCountry(e.value)}
                          optionLabel="name"
                          filter
                          showClear
                          filterBy="name"
                          placeholder="Select a Country"
                          valueTemplate={this.selectedCountryTemplate}
                          itemTemplate={this.countryOptionTemplate}
                        />
                      </div> */}

                      <div className="form-group">
                        <label className="footer-cotect-label">
                          Veuillez choisir votre projet*
                        </label>
                        <select
                          id="inputProjectFooter"
                          className="form-control inpt"
                          onChange={this.handleProject}
                        >
                          <option value="">
                            Veuillez choisir votre projet*
                          </option>
                          {this.renderOptionProjects()}
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="footer-cotect-label">
                          Comment vous nous avez connus ?*
                        </label>
                        <select
                          id="inputsocial"
                          className="form-control inpt"
                          onChange={this.handleRefer}
                        >
                          <option value="">
                            Comment vous nous avez connus ?*
                          </option>
                          <option value="Facebook">Facebook</option>
                          <option value="Instagram">Instagram</option>
                          <option value="Bannière web">Bannière web</option>
                          <option value="Google">Google</option>
                          <option value="YouTube">YouTube</option>
                          <option value="Gmail">Gmail</option>
                          <option value="Mubawab">Mubawab</option>
                          <option value="Parrainage client">
                            Parrainage client
                          </option>
                          <option value="Recommandé par autre">
                            Recommandé par autre
                          </option>
                          <option value="Bureau de vente">
                            Bureau de vente
                          </option>
                          <option value="Habillage véhicule">
                            Habillage véhicule
                          </option>
                          <option value="Brochure / flyer publicitaire">
                            Brochure / flyer publicitaire
                          </option>
                          <option value="Panneaux Publicitaires">
                            Panneaux Publicitaires
                          </option>
                          <option value="Palissade">Palissade</option>
                          <option value="Panneaux signalétiques">
                            Panneaux signalétiques
                          </option>
                          <option value="La Fondation Mohamed VI">
                            La Fondation Mohamed VI
                          </option>
                          <option value="Wana Corporate">Wana Corporate</option>
                          <option value="Influenceur/blogueur">
                            Influenceur/blogueur
                          </option>
                          <option value="Waze">Waze</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="footer-cotect-label">Message</label>
                        <textarea
                          className="form-control inpt"
                          id="validationTextarea"
                          placeholder="Message"
                          value={this.state.message}
                          onChange={this.handleMessage}
                        ></textarea>
                      </div>
                      <div className="row align-items-end">
                        <button
                          id="btn"
                          onClick={this.send.bind(this)}
                          className="col btn btn-primary"
                        >
                          Envoyer
                        </button>
                      </div>
                      {this.state.messageError !== "" ? (
                        <p className="submit-alert mt-2">
                          {this.state.messageError}
                        </p>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div className="col-lg-8 col-md-12 offset-lg-4">
                      <p>
                        Conformément à la loi 09-08, vous disposez d'un droit
                        d'accès, de rectification et d'opposition au traitement
                        de vos données personnelles. Ce traitement a été notifié
                        à la CNDP au titre du récépissé n°...
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="Contact"
          className="footer-contact"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="container">
            <div className="row align-items-center" id="contact-section">
              <div className="footer-contact-bloc">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="footer-contact-desc">
                        <div className="footer-contact-desc-contect">
                          <h3 className="footer-contact-desc-titre">
                            Nous contacter
                          </h3>
                          <div className="footer-contact-desc-text">
                            <p>
                              N’hésitez pas à remplir notre formulaire pour que
                              l’un de nos conseillers puisse vous renseigner
                              davantage
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label className="footer-cotect-label">
                          Nom de famille*
                        </label>
                        <input
                          type="text"
                          className="form-control inpt"
                          id="nom"
                          placeholder="Nom*"
                          value={this.state.lastName}
                          onChange={this.handleLastName}
                        />
                      </div>
                      <div className="form-group">
                        <label className="footer-cotect-label">Prénom*</label>
                        <input
                          type="text"
                          className="form-control inpt"
                          id="prenom"
                          placeholder="Prénom*"
                          value={this.state.firstName}
                          onChange={this.handleFirstName}
                        />
                      </div>
                      <div className="form-group">
                        <label className="footer-cotect-label">Email*</label>
                        <input
                          type="email"
                          className="form-control inpt"
                          id="exampleInputEmail1"
                          placeholder="email@exemple.com*"
                          value={this.state.email}
                          onChange={this.handleEmail}
                        />
                      </div>
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input chkinpt"
                          id="exampleCheck1"
                          checked={this.state.condition}
                          onChange={this.handleCondition}
                        />
                        <label className="form-check-label" htmlFor="exampleCheck1">
                          J'ai lu et j'accepte{" "}
                          <a
                            style={{ color: "#b5a282" }}
                            href="/conditions-generales"
                            target="_blank"
                          >
                            les conditions générales d'utilisation{" "}
                          </a>{" "}
                          notamment la mention relative à{" "}
                          <a
                            style={{ color: "#b5a282" }}
                            href="/politique-de-confidentialite"
                            target="_blank"
                          >
                            la protection des données personnelles
                          </a>
                          .
                        </label>
                      </div>
                      <div className="form-group">
                        <p className="my-2 form-check-p">
                          Conformément à la loi 09-08, vous disposez d'un droit
                          d'accès, de rectification et d'opposition au
                          traitement de vos données personnelles. Ce traitement
                          a été notifié à la CNDP au titre du récépissé n°...
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-row">
                        <div className="form-group w-100">
                          <label className="footer-cotect-label">Téléphone*</label>
                          <div className="phone-field">
                            {/* <select
                              id="inputCountry"
                              className="form-control inpt"
                              onChange={this.handleCountry}
                            >
                              {this.renderOption()}
                            </select>
                            <input
                              type="text"
                              className="form-control inpt country-code"
                              id="inputPhoneCode"
                              placeholder="+212*"
                              value={this.state.phonePrefix}
                            /> */}

                            <Dropdown
                              value={this.state.selectedCountry}
                              options={this.state.Countries}
                              onChange={(e) => this.setSelectedCountry(e.value)}
                              optionLabel="name"
                              filter
                              showClear
                              filterBy="name"
                              className="contact-dropdown"
                              placeholder="Select a Country"
                              valueTemplate={this.selectedCountryTemplate}
                              itemTemplate={this.countryOptionTemplate}
                            />
                            <input
                              type="number"
                              className="form-control inpt w-100"
                              id="inputPhone"
                              placeholder="Tel*"
                              value={this.state.phoneNumber}
                              onChange={this.handlePhone}
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div className="form-group">
                        <Dropdown
                          value={this.state.country}
                          options={this.state.Countries}
                          onChange={(e) => this.setSelectedCountry(e.value)}
                          optionLabel="name"
                          filter
                          showClear
                          filterBy="name"
                          placeholder="Select a Country"
                          valueTemplate={this.selectedCountryTemplate}
                          itemTemplate={this.countryOptionTemplate}
                        />
                      </div> */}

                      <div className="form-group">
                        <label className="footer-cotect-label">
                          Veuillez choisir votre projet*
                        </label>
                        <select
                          id="inputProjectFooter"
                          className="form-control inpt"
                          onChange={this.handleProject}
                        >
                          <option value="">
                            Veuillez choisir votre projet*
                          </option>
                          {this.renderOptionProjects()}
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="footer-cotect-label">
                          Comment vous nous avez connus ?*
                        </label>
                        <select
                          id="inputsocial"
                          className="form-control inpt"
                          onChange={this.handleRefer}
                        >
                          <option value="">
                            Comment vous nous avez connus ?*
                          </option>
                          <option value="Facebook">Facebook</option>
                          <option value="Instagram">Instagram</option>
                          <option value="Bannière web">Bannière web</option>
                          <option value="Google">Google</option>
                          <option value="YouTube">YouTube</option>
                          <option value="Gmail">Gmail</option>
                          <option value="Mubawab">Mubawab</option>
                          <option value="Parrainage client">
                            Parrainage client
                          </option>
                          <option value="Recommandé par autre">
                            Recommandé par autre
                          </option>
                          <option value="Bureau de vente">
                            Bureau de vente
                          </option>
                          <option value="Habillage véhicule">
                            Habillage véhicule
                          </option>
                          <option value="Brochure / flyer publicitaire">
                            Brochure / flyer publicitaire
                          </option>
                          <option value="Panneaux Publicitaires">
                            Panneaux Publicitaires
                          </option>
                          <option value="Palissade">Palissade</option>
                          <option value="Panneaux signalétiques">
                            Panneaux signalétiques
                          </option>
                          <option value="La Fondation Mohamed VI">
                            La Fondation Mohamed VI
                          </option>
                          <option value="Wana Corporate">Wana Corporate</option>
                          <option value="Influenceur/blogueur">
                            Influenceur/blogueur
                          </option>
                          <option value="Waze">Waze</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="footer-cotect-label">Message</label>
                        <textarea
                          className="form-control inpt"
                          id="validationTextarea"
                          placeholder="Message"
                          value={this.state.message}
                          onChange={this.handleMessage}
                        ></textarea>
                      </div>
                      <div className="row align-items-end">
                        <button
                          id="btn"
                          onClick={this.send.bind(this)}
                          className="col btn btn-primary"
                        >
                          Envoyer
                        </button>
                      </div>
                      {this.state.messageError !== "" ? (
                        <p className="submit-alert mt-2">
                          {this.state.messageError}
                        </p>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    {/* <div className="col-lg-8 col-md-12 offset-lg-4">
                      <p>
                        Conformément à la loi 09-08, vous disposez d'un droit
                        d'accès, de rectification et d'opposition au traitement
                        de vos données personnelles. Ce traitement a été notifié
                        à la CNDP au titre du récépissé n°...
                      </p>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Contact;
