import React, { Component } from "react";
// import image1 from "../../Images/image1.PNG";
// import logo1 from "../../Images/darlogo.png";
import "lightbox2/dist/js/lightbox.js";
import "lightbox2/dist/css/lightbox.css";
import "./../../../node_modules/react-slick/dist/react-slick";
import host from '../../config/api';
// import mainapp from '../../config/env';
import ProjectService from "../../services/project-service";
// import { faRedditSquare } from "@fortawesome/free-brands-svg-icons";
import { Link } from 'react-router-dom';
import Carousel from "bee-carousel";
import $ from "jquery";

// import Carousel from 'bee-carousel';
export class noprojet extends Component {
  constructor(props) {
    super(props);
    this.projectService = new ProjectService();
    this.state = {
      projects: [],
      status: false,
      indexnprojet: 0,
      indexvprojet: 0,
      indexHSprojet: 0,
    };
    console.log("index", props);
    this.carouselWrapperRefP = React.createRef()
  }

  async componentDidMount() {
    await this.projectService.getAllProjects().then((data) => {
      const projects = data["data"].projects;

      // Set the initial indices for projects of type "Villas" and "Moyen Standing"
      const indexvprojet = projects.findIndex((project) => project.type === "Villas");
      const indexnprojet = projects.findIndex((project) => project.type === "Moyen Standing");

      this.setState({
        projects,
        status: true,
        indexvprojet: indexvprojet !== -1 ? indexvprojet : 0,
        indexnprojet: indexnprojet !== -1 ? indexnprojet : 0,
      });
    });
    setTimeout(() => {
      $('#noprojet').removeClass('show');

      // Remove inline styles
      $('#noprojet').removeAttr('style');
    }, 500);
  }

  getRoute(route) {
    return "/" + route;
  }

  render() {
    const paramsslidehomeP = {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      slidesPerView: 3,
      spaceBetween: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    if (this.state.projects && this.state.projects.length > 0) {
      const slidehome = this.state.projects.filter((project) => project.type === "Haut Standing");
      // Remove the last item and get it
      let lastItem = slidehome.pop();

      // Insert the last item at the beginning
      slidehome.unshift(lastItem);

      return (
        <div style={{ height: "0" }}>
          {/* ---- modal -- */}
          <div
            className="modal fade swiper-slider show"
            id="noprojet"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="noprojet"
            aria-hidden="true"
            style={{display: "block",opacity: "0"}}
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="ModalLabel">
                    NOS PROJETS
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-3">
                      <h4>Villas</h4>
                    </div>
                    <div className="col-md-6">
                      <h4>Haut Standing</h4>
                    </div>
                    <div className="col-md-3">
                      <h4>Moyen Standing</h4>
                    </div>
                  </div>

                  <div className="row slide-home">
                    <div className="col-md-3">
                      <div id="carouselVprojets" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                          {this.state.projects.map((project, index) => {
                            if (project.type === "Villas") {
                              const cls = index === this.state.indexvprojet ? "carousel-item active" : "carousel-item";
                              return (
                                <div className={cls} key={index}>
                                  <div className="swiper-slide">
                                    <div
                                      style={{
                                        backgroundImage: `url(${host + "uploads/" + project.cover})`,
                                        backgroundSize: "cover",
                                        height: "400px",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                      }}
                                    >
                                      <div className="col-5 txthover">
                                        <img src={host + "uploads/" + project.logoForHome} className="d-block w-100" alt={project.logoForHome} />
                                        <p>{project.descriptionMenu}</p>
                                        <Link className="btn" to={this.getRoute(project.route)}>
                                          Découvrir
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                        <a className="carousel-control-prev" href="#carouselVprojets" role="button" data-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselVprojets" role="button" data-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"></span>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                    </div>

                    <div ref={this.carouselWrapperRefP} className="col-md-6">
                      <Carousel {...paramsslidehomeP}>
                        {slidehome.map((project, index) => (
                          <div className="swiper-slide" key={index}>
                            <div
                              style={{
                                backgroundImage: `url(${host + "uploads/" + project.cover})`,
                                backgroundSize: "cover",
                                height: "400px",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            >
                              <div className="col-5 txthover">
                                <img src={host + "uploads/" + project.logoForHome} className="d-block w-100" alt={project.logoForHome} />
                                <p>{project.descriptionMenu}</p>
                                <Link className="btn" to={this.getRoute(project.route)}>
                                  Découvrir
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    </div>

                    <div className="col-md-3">
                      <div id="carouselNprojets" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                          {this.state.projects.map((project, index) => {
                            if (project.type === "Moyen Standing") {
                              const cls = index === this.state.indexnprojet ? "carousel-item active" : "carousel-item";
                              return (
                                <div className={cls} key={index}>
                                  <div className="swiper-slide">
                                    <div
                                      style={{
                                        backgroundImage: `url(${host + "uploads/" + project.cover})`,
                                        backgroundSize: "cover",
                                        height: "400px",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                      }}
                                    >
                                      <div className="col-5 txthover">
                                        <img src={host + "uploads/" + project.logoForHome} className="d-block w-100" alt={project.logoForHome} />
                                        <p>{project.descriptionMenu}</p>
                                        <Link className="btn" to={this.getRoute(project.route)}>
                                          Découvrir
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                        <a className="carousel-control-prev" href="#carouselNprojets" role="button" data-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselNprojets" role="button" data-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"></span>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---- fin modal  -- */}
          <br />
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default noprojet;
