import React, { Component } from "react";
import "../video.css";

export class Virtuelle extends Component {
  constructor(props) {
    super(props);

    this.project = {};
  }
  componentDidMount() {
    this.video = this.props.video;
  }
  render() {

    return (
      <div className="section section-padding">
        {" "}
        <div className="row">
          <div className="zone-title">
            <h2 className="title">Visite Virtuelle</h2>
          </div>
        </div>
        <div className="container">
          <div className="row video">
            <div className="col-md-12">
              <iframe title="Description of the iframe content"
                width="100%"
                height="515"
                src="https://axeon.ma/CLIENT/CHAABAN/Anfabloom/VISITE_360/APPARTEMENT/index.html"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Virtuelle;
