import React, { Component } from "react";
import "./gelerie.css";
import "lightbox2/dist/js/lightbox.js";
import "lightbox2/dist/css/lightbox.css";
import "./../../../node_modules/swiper/dist/css/swiper.min.css";
import "./../../../node_modules/swiper/dist/css/swiper.css";
import "./../../../node_modules/react-slick/dist/react-slick";
import Carousel from "bee-carousel";
import host from "../../config/api";
import { Link } from 'react-router-dom';

class Projets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: props.projects || []
    };
  }

  componentDidMount() {
    // Optionally fetch projects from a service if needed
    // this.projectService = new ProjectService();
    // this.projectService.getProjects().then(projects => this.setState({ projects }));
  }
  componentDidUpdate(prevProps) {
    // Update the state if the projects prop has changed
    if (prevProps.projects !== this.props.projects) {
      this.setState({ projects: this.props.projects });
    }
  }
  getRoute(route) {
    return `/${route}`;
  }

  render() {
    const { projects } = this.state;

    const carouselParams = {
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      slidesPerView: 3,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    };

    return (
      <Carousel {...carouselParams}>
        {projects.map(project => (
          <div className="swiper-slide" key={project.id}>
            <Link to={this.getRoute(project.route)}>
              <img
                src={`${host}uploads/${project.cover}`}
                className="d-block w-100"
                alt={project.cover}
              />
              <h3>{project.title}</h3>
            </Link>
          </div>
        ))}
      </Carousel>
    );
  }
}

export default Projets;
