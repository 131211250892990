import React, { Component } from "react";
import "./index.css";
import Noprojet from "../projet/nosprojet";
import HeaderHome from "../websitelayout/Header-home";
import HeaderHomeMobile from "../websitelayout/Header-homeMobile";
import $ from "jquery";
import MediaQuery from "react-responsive";

export class index extends Component {
  componentDidMount() {
    $("body").removeClass();
    $("body").addClass("home-page politique");
    $('#inputProjectFooter option[value=""]').prop("selected", true);
  }
  render() {
    return (
      <div>
        <MediaQuery maxDeviceWidth={790}>
          {/* mobile */}
          <HeaderHomeMobile />
          <div id="vision-text" className="col-md-12 ml-2">
            <h1>POLITIQUE DE CONFIDENTIALITÉ</h1>
            <div className="ml-1">
              <h4>A. Introduction</h4>
              <p>
                1. La confidentialité des visiteurs de notre site web est très
                importante pour nous, et nous nous engageons à la protéger. La
                présente politique explique ce que nous faisons de vos
                informations personnelles.
              </p>

              <p>
                2. Le fait de consentir à l'utilisation de cookies conformément
                aux conditions de la présente politique lors de votre première
                visite sur notre site Web nous permet d'utiliser des cookies
                chaque fois que vous visitez notre site Web.
              </p>
              <h4>B. Comment nous collectons vos données personnelles</h4>
              <p>
                Les types d'informations personnelles suivants peuvent être
                collectés, stockés et utilisés :
              </p>

              <div className="ml-2">
                <p>
                  1. Informations sur vos visites et votre utilisation de ce
                  site web, y compris la source de référence, la durée de la
                  visite, les pages consultées et les chemins de navigation du
                  site web.
                </p>

                <p>
                  2. Les informations que vous saisissez lorsque vous vous
                  inscrivez sur notre site web, telles que votre adresse
                  électronique.
                </p>

                <p>
                  3. Les informations que vous saisissez pour remplir le
                  formulaire sur notre site web. Par exemple votre nom, votre
                  adresse, votre numéro de téléphone et votre adresse
                  électronique.
                </p>

                <p>
                  4. Les informations générées lors de l'utilisation de notre
                  site web, y compris quand, à quelle fréquence et dans quelles
                  circonstances vous l'utilisez.
                </p>

                <p>
                  5. Toute autre information personnelle que vous nous envoyez.
                </p>
              </div>

              <h4>C. Utilisation des informations personnelles</h4>

              <p>
                Les informations personnelles qui nous sont soumises par le
                biais de notre site web seront utilisées aux fins spécifiées
                dans la présente politique ou sur les pages concernées du site
                web. Nous pouvons utiliser vos informations personnelles pour
                les raisons suivantes :
              </p>

              <div className="ml-2">
                <p>1. L'envoi des communications commerciales de marketing.</p>
                <p>
                  2. L'envoi de notifications par e-mail que vous avez
                  spécifiquement demandées.
                </p>
                <p>
                  3. L'envoi de notre lettre d'information électronique si vous
                  vous y êtes inscrit (vous pouvez vous vous pouvez vous
                  désinscrire à tout moment).
                </p>
                <p>
                  4. L'envoi des communications marketing relatives à notre
                  activité , qui pourraient vous intéresser.{" "}
                </p>
                <p>
                  5. Fournir à des tiers des informations statistiques sur nos
                  utilisateurs.
                </p>
                <p>
                  6. Traiter les demandes de renseignements et les plaintes
                  formulées par vous ou à votre sujet concernant notre site web.
                </p>
                <p>
                  7. Assurer la sécurité de notre site Web et prévenir la
                  fraude.
                </p>
                <p>
                  8. Vérifier le respect des conditions d'utilisation de notre
                  site web.
                </p>
                <p>9. Autres utilisations.</p>
              </div>

              <p>
                Si vous soumettez des informations personnelles pour publication
                sur notre site web, nous publierons et utiliserons ces
                informations conformément à la licence que vous nous accordez.
                Vos paramètres de confidentialité peuvent être utilisés pour
                limiter la publication de vos informations sur notre site web et
                peuvent être ajustés en utilisant les contrôles de
                confidentialité sur le site web.
              </p>
              <p>
                Nous ne fournirons pas, sans votre consentement explicite, vos
                informations personnelles à des tiers pour leur marketing direct
                ou celui de tout autre tiers.
              </p>

              <h4>D. Divulgation d'informations personnelles</h4>
              <p>
                Nous pouvons divulguer vos informations personnelles à l'un de
                nos employés, dirigeants, conseillers commerciaux, agents,
                fournisseurs ou sous-traitants dans la mesure où cela est
                raisonnablement nécessaire aux fins énoncées dans la présente
                politique.
              </p>
              <p>
                Nous pouvons divulguer vos informations personnelles à tout
                membre de notre groupe de sociétés (c'est-à-dire nos filiales,
                notre groupe ultime et toutes ses filiales) dans la mesure où
                cela est raisonnablement nécessaire aux fins énoncées dans la
                présente politique.
              </p>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={790}>
          {/* desktop */}
          <Noprojet />
          <HeaderHome />
          <div id="vision-text" className="col-md-12 ml-2">
            <h1>POLITIQUE DE CONFIDENTIALITÉ</h1>
            <div className="ml-1">
              <h4>A. Introduction</h4>
              <p>
                1. La confidentialité des visiteurs de notre site web est très
                importante pour nous, et nous nous engageons à la protéger. La
                présente politique explique ce que nous faisons de vos
                informations personnelles.
              </p>

              <p>
                2. Le fait de consentir à l'utilisation de cookies conformément
                aux conditions de la présente politique lors de votre première
                visite sur notre site Web nous permet d'utiliser des cookies
                chaque fois que vous visitez notre site Web.
              </p>
              <h4>B. Comment nous collectons vos données personnelles</h4>
              <p>
                Les types d'informations personnelles suivants peuvent être
                collectés, stockés et utilisés :
              </p>

              <div className="ml-2">
                <p>
                  1. Informations sur vos visites et votre utilisation de ce
                  site web, y compris la source de référence, la durée de la
                  visite, les pages consultées et les chemins de navigation du
                  site web.
                </p>

                <p>
                  2. Les informations que vous saisissez lorsque vous vous
                  inscrivez sur notre site web, telles que votre adresse
                  électronique.
                </p>

                <p>
                  3. Les informations que vous saisissez pour remplir le
                  formulaire sur notre site web. Par exemple votre nom, votre
                  adresse, votre numéro de téléphone et votre adresse
                  électronique.
                </p>

                <p>
                  4. Les informations générées lors de l'utilisation de notre
                  site web, y compris quand, à quelle fréquence et dans quelles
                  circonstances vous l'utilisez.
                </p>

                <p>
                  5. Toute autre information personnelle que vous nous envoyez.
                </p>
              </div>

              <h4>C. Utilisation des informations personnelles</h4>

              <p>
                Les informations personnelles qui nous sont soumises par le
                biais de notre site web seront utilisées aux fins spécifiées
                dans la présente politique ou sur les pages concernées du site
                web. Nous pouvons utiliser vos informations personnelles pour
                les raisons suivantes :
              </p>

              <div className="ml-2">
                <p>1. L'envoi des communications commerciales de marketing.</p>
                <p>
                  2. L'envoi de notifications par e-mail que vous avez
                  spécifiquement demandées.
                </p>
                <p>
                  3. L'envoi de notre lettre d'information électronique si vous
                  vous y êtes inscrit (vous pouvez vous vous pouvez vous
                  désinscrire à tout moment).
                </p>
                <p>
                  4. L'envoi des communications marketing relatives à notre
                  activité , qui pourraient vous intéresser.{" "}
                </p>
                <p>
                  5. Fournir à des tiers des informations statistiques sur nos
                  utilisateurs.
                </p>
                <p>
                  6. Traiter les demandes de renseignements et les plaintes
                  formulées par vous ou à votre sujet concernant notre site web.
                </p>
                <p>
                  7. Assurer la sécurité de notre site Web et prévenir la
                  fraude.
                </p>
                <p>
                  8. Vérifier le respect des conditions d'utilisation de notre
                  site web.
                </p>
                <p>9. Autres utilisations.</p>
              </div>

              <p>
                Si vous soumettez des informations personnelles pour publication
                sur notre site web, nous publierons et utiliserons ces
                informations conformément à la licence que vous nous accordez.
                Vos paramètres de confidentialité peuvent être utilisés pour
                limiter la publication de vos informations sur notre site web et
                peuvent être ajustés en utilisant les contrôles de
                confidentialité sur le site web.
              </p>
              <p>
                Nous ne fournirons pas, sans votre consentement explicite, vos
                informations personnelles à des tiers pour leur marketing direct
                ou celui de tout autre tiers.
              </p>

              <h4>D. Divulgation d'informations personnelles</h4>
              <p>
                Nous pouvons divulguer vos informations personnelles à l'un de
                nos employés, dirigeants, conseillers commerciaux, agents,
                fournisseurs ou sous-traitants dans la mesure où cela est
                raisonnablement nécessaire aux fins énoncées dans la présente
                politique.
              </p>
              <p>
                Nous pouvons divulguer vos informations personnelles à tout
                membre de notre groupe de sociétés (c'est-à-dire nos filiales,
                notre groupe ultime et toutes ses filiales) dans la mesure où
                cela est raisonnablement nécessaire aux fins énoncées dans la
                présente politique.
              </p>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default index;
