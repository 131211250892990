/* eslint-disable */
import React, { Component } from "react";
import Carousel from "bee-carousel";
import ProjectService from "../../services/project-service";
import mainapp from "../../config/env";
import host from "../../config/api";
import { Link } from "react-router-dom";
export class SlideHome extends Component {
  constructor(props) {

    super(props);
    this.projectService = new ProjectService();
    this.state = { projects: [], status: false };
    // this.projectService.getAllProjects().then(data => {
    //   this.setState({ projects: data["data"].projects, status: true });
    //   //localStorage.setIte
    // });

  }
  async componentDidMount() {


    await this.projectService.getAllProjects().then(data => {
      this.setState({ projects: data["data"].projects, status: true });
      //localStorage.setIte
    });
  }
  getRoute(route) {


    return "/" + route;
  }

  render() {

    if (this.state.projects && this.state.projects.length > 0) {
      const paramsslidehome = {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerView: 3,
        spaceBetween: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      };
      const projects = this.state.projects;


      return (
        <div style={{ backgroundColor: "#fff", padding: "80px  0 80px 80px " }}>
          <div className="row slide-home">
            <div className="col-5">
              <h3
                style={{
                  color: "#04151f",
                  fontSize: "21px",
                  fontWeight: "700"
                }}
              >
                BIENVENUE SUR CHAABANE IMMOBILIER
              </h3>
               <p style={{ color: "#8e8985" }}>
                Chaâbane Immobilier conçoit des projets d’envergure situés dans
                les principales villes du Royaume, des lieux de vie uniques , où
                seuls la qualité, le raffinement, le confort et l’innovation
                priment.
              </p>
            </div>
            <div className="col-7">
              <Carousel {...paramsslidehome}>
                {projects.map((projet, index) => (
                  <div className="swiper-slide  " key={index} >
                    <div
                      style={{
                        backgroundImage: `url(${host +
                          "uploads/" +
                          projet.cover})`,
                        backgroundSize: "cover",
                        height: "470px",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                      }}
                    >
                      <div className="col-5 txthover">
                        <img
                          src={host + "uploads/" + projet.logoForHome}
                          className="d-block w-100"
                        />
                        <p>{projet.descriptionMenu}</p>
                        <Link className="btn" to={this.getRoute(projet.route)}>
                          Découvrir
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
              <div className="col-8" style={{ padding: "0" }}>
                <h3
                  style={{
                    color: "#04151f",
                    fontSize: "21px",
                    fontWeight: "700",
                    margin: "25px 0"
                  }}
                >
                  DÉCOUVREZ L'ENSEMBLE DE NOS PROJETS
                </h3>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default SlideHome;
