import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import $ from "jquery";
import MediaQuery from "react-responsive";
import Noprojet from "../projet/nosprojet";
import HeaderHome from "../websitelayout/Header-home";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import HeaderHomeMobile from "../websitelayout/Header-homeMobile";
import ActualityService from '../../services/actuality-service';
import host from "../../config/api";

const SingleActualite = () => {
  const { ref } = useParams(); // Accessing the ref parameter
  const [actuality, setActuality] = useState(null);
  const [date, setDate] = useState('');
  const actualityService = new ActualityService();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await actualityService.getActualityInfos(ref);
        const actualityData = data?.data?.Actuality;
        if (actualityData) {
          setActuality(actualityData);
          const months = ["JANVIER", "FÉVRIER", "MARS", "AVRIL", "MAI", "JUIN", "JUILLET", "AOÛT", "SEPTEMBRE", "OCTOBRE", "NOVEMBRE", "DÉCEMBRE"];
          const current_datetime = new Date(actualityData.datePobulication);
          const formatted_date = `${current_datetime.getDate()}-${months[current_datetime.getMonth()]}-${current_datetime.getFullYear()}`;
          setDate(formatted_date);
        }
      } catch (error) {
        console.error("Error fetching actuality data:", error);
      }
    };

    fetchData();

    $("body").removeClass().addClass("actuality");
    $('#inputProjectFooter option[value=""]').prop('selected', true);
  }, [ref]);

  if (!actuality) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <MediaQuery maxDeviceWidth={790}>
        {/* mobile */}
        <HeaderHomeMobile />
        <div style={{ backgroundColor: "white", padding: "80px 10px" }}>
          <h1 className="actuality-titre">NOS ACTUALITÉS</h1>
          <div className="container">
            <div className="row">
              <div className="article col-md-10">
                <img src={`${host}uploads/${actuality.image}`} className="d-block w-100" alt={actuality.image} />
                <div>
                  <p className="title">{actuality.title}</p>
                  <p className="date"><FontAwesomeIcon icon={faCalendarAlt} /> {date}</p>
                  <div dangerouslySetInnerHTML={{ __html: actuality.text }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery minDeviceWidth={790}>
        {/* desktop */}
        <Noprojet />
        <HeaderHome />
        <div className="home-page" style={{ backgroundColor: "white", padding: "80px 10px" }}>
          <h1 style={{ textAlign: "center", color: "#0d0c0b", margin: "0 auto 50px auto", borderColor: "#b7853e" }}>
            NOS ACTUALITÉS
          </h1>
          <div className="container">
            <div className="row">
              <div className="article col-md-10">
                <img src={`${host}uploads/${actuality.image}`} className="d-block w-100" alt={actuality.image} />
                <div>
                  <p className="title">{actuality.title}</p>
                  <p className="date"><FontAwesomeIcon icon={faCalendarAlt} /> {date}</p>
                  <div dangerouslySetInnerHTML={{ __html: actuality.text }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
    </div>
  );
};

export default SingleActualite;
