import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import Acceuil from "../acceuil";
import Contact from "../contact";
import Remerciements from "../remerciements";
import Apropos from "../apropos";
import Project from "../projet";
import Carriere from "../carriere";
import Magichouse from "../projet/magic-house";
import Magichousebeach from "../projet/magic-house-beach";
import Ricoflorpalmes from "../projet/ricoflor-palmes";
import Thesandhouse from "../projet/the-sand-house";
import Bluewaterhouse from "../projet/blue-water-house";
import Gardenbay from "../projet/garden-bay";
import Lesperlesdebentriaa from "../projet/les-perles-de-bentriaa";
import Le16emeAngle from "../projet/16eme-angle";
import AnfaBloom from "../projet/AnfaBloom";
import Actualites from "../actualites";
import SingleActualite from "../single-actualite";
import ConditionGenerales from "../conditions-generales";
import PolitiqueDeConfidentialite from "../politique-de-confidentialite";

export class Section extends Component {
  render() {
    return (
      <Routes>
        <Route path="/Project/the-sand-house" element={<Thesandhouse />} />
        <Route path="/Project/ricoflores-palm" element={<Ricoflorpalmes />} />
        <Route path="/Project/magic-house" element={<Magichouse />} />
        <Route path="/Project/magic-house-beach" element={<Magichousebeach />} />
        <Route path="/Project/blue-waters-house" element={<Bluewaterhouse />} />
        <Route path="/Project/garden-bay" element={<Gardenbay />} />
        <Route path="/Project/les-perles-de-bentriaa" element={<Lesperlesdebentriaa />} />
        <Route path="/Project/16eme-angle" element={<Le16emeAngle />} />
        <Route path="/Project/AnfaBloom" element={<AnfaBloom />} />

      <Route path="/Project" element={<Project />}>
      </Route>

      <Route path="/Apropos" element={<Apropos />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/remerciements" element={<Remerciements />} />
      <Route path="/carriere" element={<Carriere />} />
      <Route path="/actualites" element={<Actualites />} />
      <Route path="/conditions-generales" element={<ConditionGenerales />} />
      <Route path="/politique-de-confidentialite" element={<PolitiqueDeConfidentialite />} />
      <Route path="/article/:ref" element={<SingleActualite />} />
      <Route path="/" element={<Acceuil />} />
    </Routes>
    );
  }
}

export default Section;
