import React, { Component } from "react";
import image from "../../Images/footer-contact.png";
import { faFacebook,faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt,faFax,faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class ContactInfo extends Component {
  render() {
    return (
      <div id="infocontact-section" className="row align-items-center" style={{backgroundColor:"#fff"}}>
        <div id="maps_iframe" className="col-md-5">
        <iframe title="Description of the iframe content" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.3456423788507!2d-7.6170564854126654!3d33.54439485176956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda62d5ec31c657b%3A0xc2faabd2ae952464!2sCHAABANE%20IMMOBILIER!5e0!3m2!1sfr!2sma!4v1582146425589!5m2!1sfr!2sma" width="100%" height="463px" frameBorder="0" style={{border:"0"}}  allowFullScreen=""></iframe>
        </div>
        <div id="infocontact-text" className="col-md-7">
          <h5>CHAABANE IMMOBILIER</h5>
          <p><FontAwesomeIcon icon={faMapMarkerAlt} />  7 Lotissement Ghita Avenue « N » Californie Casablanca Maroc</p>
          <div className="row">
<div className="col-md-6">
<img src={image} alt=""></img>
</div>
<div className="col-md-6">
<ul className="navbar-nav mr-auto">
                <li className="nav-item info"><FontAwesomeIcon icon={faPhoneAlt} />
                Tél: <a href="tel:+212522211717">+212 522 21 17 17 </a><br/> Gsm:  <a href="tel:+212614989898">+212 614 98 98 98</a>
                </li> 
                <li className="nav-item info"><FontAwesomeIcon icon={faEnvelope} />
                <a href="mailto:contact@chaabaneinvest.com">contact@chaabaneinvest.com</a>
                </li> 
                 <li className="nav-item info"><FontAwesomeIcon icon={faFax} />
                Fax: <a href="tel:05 22 50 03 10">05 22 50 03 10</a>
                </li> 
                <li className="nav-item info"><FontAwesomeIcon icon={faInstagram} /> 
                @chaabane.immobilier
                </li>
                <li className="nav-item info"> <FontAwesomeIcon icon={faFacebook} /> 
                 Chaabaneimmobilier
                </li>
                <li className="nav-item info"><FontAwesomeIcon icon={faYoutube} /> 
                 Chaabane immobilier
                </li>
            </ul>
</div>
          </div>

        </div>
      </div>
    );
  }
}

export default ContactInfo;
