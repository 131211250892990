import React, { Component } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

export class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear();

    return (
      <div>
        <footer className="footer">
          <div className="col-md-3">
            <ul className="url">
              <li>
                <a className="url" target="_blank" href="/conditions-generales">
                  CONDITIONS GÉNÉRALES D’UTILISATION
                </a>
              </li>
              <li>
                <a
                  className="url"
                  target="_blank"
                  href="/politique-de-confidentialite"
                >
                  POLITIQUE DE CONFIDENTIALITÉ
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-copyright text-center">
            © {currentYear} CHAABANE IMMOBILIER
            <div
              style={{ float: "right", right: "40px", position: "relative" }}
            >
              <a
                className="social-media smedia-icon"
                target="_blank"
                href="https://fr-fr.facebook.com/Chaabaneimmobilier/"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                className="social-media smedia-icon"
                target="_blank"
                href="https://www.linkedin.com/company/chaabane-immobilier/"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                className="social-media smedia-icon"
                target="_blank"
                href="https://www.instagram.com/chaabane.immobilier/?hl=fr"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
