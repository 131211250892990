import React, { Component } from 'react'
import calendar from "../../Images/calendar.png";
import quality from "../../Images/quality.png";
import like from "../../Images/like.png";
import transparency from "../../Images/transparency.png";

export class engagements extends Component {
    render() {
        return (
            
        <div id="engagements-section" className="container animated animatedFadeInUp fadeInUp"> 
                <h1 style={{ textAlign: 'center'}}>NOS ENGAGEMENTS</h1>
                <br></br>  
                <br></br>  
                <br></br>  
                    <div className="row ">
                        <div className="col-3">
                            <div className="text-center"> 
                            <img src={calendar} className="d-block w-100" alt="..." />
                                <div className="title">
                                    <h3>ENGAGEMENT</h3>
                                 
                                </div>
                                <div className="text">
                                    <span>
                                    Notre engagement envers nos clients vient appuyer notre philosophie et créer une relation de confiance avec nos collaborateurs. Le groupe Chaâbane Invest s’engage à livrer des produits de qualité à la hauteur de son image de marque.
                                    </span>
                                </div>
                            </div>
                        </div>	 
                        <div className="col-3">
                            <div className="text-center">
                            <img src={quality} className="d-block w-100" alt="..." />
                                <div className="title">
                                    <h3>QUALITÉ</h3>
                                 
                                </div>
                                <div className="text">
                                    <span>
                                        Pour votre bien-être, le groupe se doit d’utiliser des matériaux de qualité, Chaâbane Invest fait affaire avec les meilleurs fournisseurs sur le marché, un moyen pour l’entreprise de garantir à ses collaborateurs une qualité irréprochable du produit final.
                                    </span>
                                </div>
                            </div>
                        </div>	 
                        <div className="col-3">
                            <div className="text-center">
                            <img src={like} className="d-block w-100" alt="..." />
                                <div className="title">
                                    <h3>SATISFACTION</h3>
                                 
                                </div>
                                <div className="text">
                                    <span>
                                        Pour satisfaire nos clients, nous mettons en
oeuvre tout notre savoir-faire et notre
expérience. Pour chaque projet, la
satisfaction de nos collaborateurs est notre
moyen d’évaluer nos efforts et définir les
points à améliorer.
                                    </span>
                                </div>
                            </div>
                        </div>	 
                        <div className="col-3">
                            <div className="text-center"> 
                            <img src={transparency} className="d-block w-100" alt="..." />
                                <div className="title">
                                    <h3>TRANSPARENCE</h3>
                                 
                                </div>                                   
                                <div className="text">
                                    <span>
                                        Le Groupe Chaâbane Invest se doit de
protéger son image de marque, en
déclarant les montants à 100%. Le groupe
s’engage à collaborer dans une
transparence totale.
                                    </span>
                                </div>                                    
                            </div>
                        </div>                    
                    </div>		
        </div>    
        )
    }
}

export default engagements
