import React, { Component } from "react";
import "./video.css";

export class Video extends Component {
  constructor(props) {
    super(props);
    this.index = 0;

    this.project = {};
  }

  componentDidMount() {
    this.project = this.props.project;
    console.log('projet', this.project);
  }

  render() {
    // Destructure project from props
    const { project } = this.props;
    // Ensure project and urlYoutube are defined
    const projects = project && project.urlYoutube ? project.urlYoutube : [];

    return (
      <div className="section section-padding">
        <div className="row">
          <div className="zone-title">
            <h2 className="title">Video</h2>
          </div>
        </div>
        <div className="container">
          <div className="row video">
            <div className="col-md-8">
              <div id="carouselExampleControlsvideo" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                  {projects.length > 0 ? (
                    projects.map((url, index) => (
                      <div
                        className={index === this.index ? 'carousel-item active' : 'carousel-item'}
                        key={index}
                      >
                        <iframe
                          title={`Video ${index}`}
                          width="560"
                          height="315"
                          src={url}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    ))
                  ) : (
                    <div className="carousel-item">
                      <p>No videos available.</p>
                    </div>
                  )}
                </div>
                <a className="carousel-control-prev" href="#carouselExampleControlsvideo" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControlsvideo" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="all-centre">
                <h2 style={{ color: "#fff" }}>
                  <span>videothéque</span>
                  <br /> du projet
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
