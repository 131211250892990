import React, { Component } from "react";
import ContactForm from "./contact-form";
import ContactInfo from "./contact-info";
import "./index.css";
import HeaderHome from "../websitelayout/Header-home";
import HeaderHomeMobile from "../websitelayout/Header-homeMobile";
import $ from "jquery";
import Noprojet from "../projet/nosprojet"
import MediaQuery from "react-responsive";
import Engagementimage from '../../Images/background.jpg'

export class index extends Component {
  componentDidMount() {
    $("body").removeClass();
    $("body").addClass("contact");
    $('#inputProjectFooter option[value=""]').prop('selected', true);
  }
  render() {
    return (
      <div>
        <MediaQuery maxDeviceWidth={790}>
          {/* mobile */}
          <HeaderHomeMobile />
        </MediaQuery>
        <MediaQuery minDeviceWidth={790}>
          {/* desktop */}
          <Noprojet />
          <HeaderHome />
          <div id="nouscontacter-section" className="row align-items-center">
            <h1>NOUS CONTACTER</h1>
          </div>
          <div style={{backgroundImage: `linear-gradient(rgba(151, 122, 60, 0.52), rgba(151, 122, 60, 0.45)),
           url(${Engagementimage})`,backgroundSize: "cover",backgroundAttachment: "fixed"}}>
               <ContactForm />
            <br />
          </div>
          <div>
            <ContactInfo />
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default index;
