import React, { Component } from "react";
// import first from "../../Images/silde1.jpg";
// import second from "../../Images/silde2.jpg";
// import logo1 from "../../Images/darlogo.png";
// import logo2 from "../../Images/darlogo.png";
import { Link } from "react-router-dom";
// import image1 from "../../Images/image1.PNG";
// import image2 from "../../Images/image2.PNG";
// import image3 from "../../Images/image3.PNG";
// import image4 from "../../Images/image4.png";
import Carousel from 'bee-carousel';
import ProjectService from "../../services/project-service";
import host from "../../config/api";

export class SlideHomeMobile extends Component {
  constructor(props) {

    super(props);
    this.projectService = new ProjectService();
    this.state = { projects: [], projectsHome:[], status: false };
    this.projects = {};
  }
  async componentDidMount() {

    this.projects = this.props.projects;
    console.log('project', this.projects);
    await this.projectService.getAllProjects().then(data => {
      this.setState({ projectsHome: data["data"].projects, status: true });
      //localStorage.setIte
    });

  }
  /* componentDidMount() {

    
  }*/

  getRoute(route) {
    return "/" + route;
  }

  render() {
    /*if (this.state.projects && this.state.projects.length > 0) {
      const projects = this.state.projects;*/
    if (this.props.projects && this.props.projects.length > 0) {
      const params = {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        slidesPerView: 1,
        spaceBetween: 20,
      }
      
    return(
      <div className="first" style={{ backgroundColor: '#fff',padding: '30px 30px 60%'}}>
      <div className="row slide-home none">
      <div className="col-12">
<h3 style={{ color: 'rgb(183, 133, 62)', fontSize: '21px',fontWeight: '700'}}>BIENVENUE SUR CHAABANE IMMOBILIER</h3>
<p style={{ color: '#8e8985'}}>Chaâbane Immobilier conçoit des projets d’envergure situés dans les principales villes du Royaume, des lieux de vie uniques , où seuls la qualité, le raffinement, le confort et l’innovation priment.</p>
        </div>
        </div>
        <div className="row slide-home">
       <div className="col-12">
         <h3 className="none" style={{ color: 'rgb(183, 133, 62)', fontSize: '21px',fontWeight: '700',marginBottom:"35px"}}>DÉCOUVREZ L'ENSEMBLE DE NOS PROJETS :</h3>
      <Carousel {...params}>
      {this.state.projectsHome.map((projet, index) => (
                  <div className="swiper-slide  " key={index} >
                      <div className="col-5 txthover">
                        <img
                          src={host + "uploads/" + projet.cover}
                          className="d-block w-100"
                        />
                        <h3>{projet.title}</h3>
                        <p>{projet.descriptionMenu}</p>
                        <Link className="btn" to={this.getRoute(projet.route)}>
                          Découvrir
                        </Link>
                      </div>
                  </div>
                ))}
  </Carousel>
      </div>
      </div>
      </div>
    );
  } else if(this.state.projectsHome && this.state.projectsHome.length > 0 && !this.props.projects && this.props.source === 'home'){
    const params2 = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      slidesPerView: 1,
      spaceBetween: 20,
    }
    return (<div className="first" style={{ backgroundColor: '#fff',padding: '30px 30px 60%'}}>
    <div className="row slide-home none">
    <div className="col-12">
<h3 style={{ color: 'rgb(183, 133, 62)', fontSize: '21px',fontWeight: '700'}}>BIENVENUE SUR CHAABANE IMMOBILIER</h3>
<p style={{ color: '#8e8985'}}>Chaâbane Immobilier conçoit des projets d’envergure situés dans les principales villes du Royaume, des lieux de vie uniques , où seuls la qualité, le raffinement, le confort et l’innovation priment.</p>
   </div>
      </div>
      <div className="row slide-home">
     <div className="col-12">
       <h3 className="none" style={{ color: 'rgb(183, 133, 62)', fontSize: '21px',fontWeight: '700',marginBottom:"35px"}}>DÉCOUVREZ L'ENSEMBLE DE NOS PROJETS :</h3>
    <Carousel {...params2}>
    {this.state.projectsHome.map((projet, index) => (
                <div className="swiper-slide  " key={index} >
                    <div className="col-5 txthover">
                      <img
                        src={host + "uploads/" + projet.cover}
                        className="d-block w-100"
                      />
                      <h3>{projet.title}</h3>
                      <p>{projet.descriptionMenu}</p>
                      <Link className="btn" to={this.getRoute(projet.route)}>
                        Découvrir
                      </Link>
                    </div>
                </div>
              ))}
</Carousel>
    </div>
    </div>
    </div>);
  } else{
    return(<div></div>)
  }
  }
}

export default SlideHomeMobile;
