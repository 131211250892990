import React, { Component } from 'react'
import Engagements from './engagements'
import EngagementsMobile from './engagementsMobile'
import MotDePresident from './motPresident'
import NotreGroup from './notregroupe'
import Noprojet from "../projet/nosprojet"
import Vision from './vision'
import './index.css'
import HeaderHome from "../websitelayout/Header-home";
import HeaderHomeMobile from "../websitelayout/Header-homeMobile";
import $ from "jquery";
import MediaQuery from "react-responsive";
import Engagementimage from '../../Images/background.jpg'


export class index extends Component {
    componentDidMount() {
        $("body").removeClass();
        $("body").addClass("home-page apropos");
        $('#inputProjectFooter option[value=""]').prop('selected', true);
      }
    render() {
        return (
            <div> 
        <MediaQuery maxDeviceWidth={790}>
          {/* mobile */}
          <HeaderHomeMobile />
          <div style={{backgroundColor:'white'}}><NotreGroup /><hr/><br/></div>
               
          <div style={{backgroundColor:'white'}}><MotDePresident /><hr/><br/></div>
                
                <div style={{backgroundColor:'white'}}><Vision /><br/></div>
               
                <div className="mobile-engagements" style={{backgroundImage: `linear-gradient(rgba(151, 122, 60, 0.52), rgba(151, 122, 60, 0.45)),
           url(${Engagementimage})`,backgroundSize: "cover",backgroundAttachment: "fixed"}}><hr/><EngagementsMobile /><br/></div>  
        </MediaQuery>
        <MediaQuery minDeviceWidth={790}>
          {/* desktop */}
          <Noprojet/>
          <HeaderHome />
          <div style={{backgroundColor:'white'}}><NotreGroup /><hr/><br/></div>
               
                <div style={{backgroundColor:'white'}}><MotDePresident /><hr/><br/></div>
                
                <div style={{backgroundColor:'white'}}><Vision /> <hr/><br/></div>
               
                <div style={{backgroundImage: `linear-gradient(rgba(151, 122, 60, 0.52), rgba(151, 122, 60, 0.45)),
           url(${Engagementimage})`,backgroundSize: "cover",backgroundAttachment: "fixed"}}>
<Engagements /><br/></div>  
        </MediaQuery>             
                       
            </div>
            

        )
    }
}

export default index
