import React, { Component } from "react";

import fullsize from "../../../Images/full-size.png";
import "lightbox2/dist/js/lightbox.js";
import "lightbox2/dist/css/lightbox.css";
import host from "../../../config/api";
import MediaQuery from "react-responsive";
import Carousel from "bee-carousel";
import "./plan.css"

export class plans extends Component {
  constructor(props) {
    super(props);

    this.project = {};
    this.plans = [];
    this.index = 0;
  }
  componentDidMount() {
    this.project = this.props.project;
    this.plans = [];
  }
  changeArray(index) {
    this.plans = this.props.project.nosPlans[index].image;
    console.log("this.test",this.plans);
    console.log("this.props.project.nosPlans[index].image",this.props.project.nosPlans[index].image);
    this.forceUpdate();
  }
  render() {
    const params = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      slidesPerView: 1.1,
      spaceBetween: 10,
    };
    const params2 = {
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      slidesPerView: 3,
      spaceBetween: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    };

    this.project = this.props.project;
    if (this.props.project) {
      return (
        <div>
          <div  style={{ padding: "15px 30px" }}>
            <MediaQuery maxDeviceWidth={790}>
              {/* mobile */}
              <div class="mob_plans">
              <Carousel {...params}>
                {this.props.project.nosPlans.map((plan, index) => (
                  <div class="swiper-slide" style={{
                    zIndex: "1050",
                  }}>
                    <div class="">
                      <div
                        class="card bg-light border-primary"
                        style={{
                          border: "1px solid",
                          borderColor: "#fff!important",
                          borderRadius: "10px"
                        }}
                      >
                        <div
                          id={"carouselPlansControls" + index}
                          class="carousel slide"
                          data-ride="carousel"
                        >
                          <div class="carousel-inner">
                            {plan.image.map(img => (

                              <div class="carousel-item active">
                                <a>
                                  <img src={host + "uploads/" + img} alt="" />
                                </a>
                              </div>
                            ))}
                          </div>
                          <a
                            class="carousel-control-prev"
                            href={"#carouselPlansControls" + index}
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a
                            class="carousel-control-next"
                            href={"#carouselPlansControls" + index}
                            role="button"
                            data-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="sr-only">Next</span>
                          </a>
                          <a
                            class="fullsize"
                            href={host + "uploads/" + plan.image[0]}
                            data-toggle="modal"
                            data-target={"#swiper-slide" + index +1}
                            // onClick={() => this.changeArray(index)}
                          >
                            <img src={fullsize} alt="" onClick={() => this.changeArray(index)} />
                          </a>
                        </div>

                        <div class="card-body">
                          <h2
                            style={{
                              color: "#554f31",
                              textAlign: "left",
                              fontSize: "20px",
                              paddingLeft: "25px"
                            }}
                          >
                            {plan.title}
                          </h2>
                          <ul>
                            {plan.informations.map(info => (
                              <li>{info}</li>
                            ))}
                          </ul>
                        </div>
                                                   {/* ---- modal slider -- */}
              <div
                class="modal fade swiper-slider"
                id={"swiper-slide" + index + 1}
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document"  style={{
                              width: "82%",
                            }}>

                  <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                      {/* ---- slider -- */}
                      <div
                        id={"carouselPlansControlsmodal" + index + 1}
                        class="carousel slide"
                        data-ride="carousel"
                      >
                        <div class="carousel-inner">
                        
                          {this.props.project.nosPlans[index].image.map((slideplan, index) => {
                            console.log("llssss");
                            const cls =
                              index === this.index
                                ? "carousel-item active"
                                : "carousel-item";
                            return (
                              <div class={cls}>
                                <img
                                  class="d-block w-100"
                                  src={host + "uploads/" + slideplan}
                                  alt={slideplan}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <a
                          class="carousel-control-prev"
                          href={"#carouselPlansControlsmodal" + index + 1}
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a
                          class="carousel-control-next"
                          href={"#carouselPlansControlsmodal" + index + 1}
                          role="button"
                          data-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                      {/* -------------- */}
                    </div>
                  </div>
                </div>
              </div>
              {/* ---- fin modal slider plans 1 -- */}

                      </div>
                    </div>
                  </div>
                  
                ))}

              </Carousel>

              </div>
         
            </MediaQuery>


            <MediaQuery minDeviceWidth={790}>
              {/* mobile */}
              <div class="plans">
              <Carousel {...params2}>
                {this.props.project.nosPlans.map((plan, index) => (
                  <div class="swiper-slide" style={{
                    zIndex: "1050",
                  }}>
                    <div class="">
                      <div
                        class="card bg-light border-primary"
                        style={{
                          border: "1px solid",
                          borderColor: "#fff!important",
                          borderRadius: "10px"
                        }}
                      >
                        <div
                          id={"carouselPlansControls" + index}
                          class="carousel slide"
                          data-ride="carousel"
                        >
                          <div class="carousel-inner">
                            {plan.image.map(img => (

                              <div class="carousel-item active">
                                <a>
                                  <img src={host + "uploads/" + img} alt="" />
                                </a>
                              </div>
                            ))}
                          </div>
                          <a
                            class="carousel-control-prev"
                            href={"#carouselPlansControls" + index}
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a
                            class="carousel-control-next"
                            href={"#carouselPlansControls" + index}
                            role="button"
                            data-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="sr-only">Next</span>
                          </a>
                          <a
                            class="fullsize"
                            href={host + "uploads/" + plan.image[0]}
                            data-toggle="modal"
                            data-target={"#swiper-slide"}
                            // onClick={() => this.changeArray(index)}
                          >
                            <img src={fullsize} alt="" onClick={() => this.changeArray(index)} />
                          </a>
                        </div>

                        <div class="card-body">
                          <h2
                            style={{
                              color: "#554f31",
                              textAlign: "left",
                              fontSize: "20px",
                              paddingLeft: "25px"
                            }}
                          >
                            {plan.title}
                          </h2>
                          <ul>
                            {plan.informations.map(info => (
                              <li>{info}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                ))}

              </Carousel>

                                                   {/* ---- modal slider -- */}
                                                   <div
                class="modal fade swiper-slider"
                id={"swiper-slide"}
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document"  style={{
                              width: "45%",
                            }}>

                  <div class="modal-content">
                    <button style={{
                              top: "-40px",
                            }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                      {/* ---- slider -- */}
                      <div
                        id={"carouselPlansControlsmodal"}
                        class="carousel slide"
                        data-ride="carousel"
                      >
                        <div class="carousel-inner">
                        
                          {this.plans.map((slideplan, index) => {
                            console.log("llssss");
                            const cls =
                              index === this.index
                                ? "carousel-item active"
                                : "carousel-item";
                            return (
                              <div class={cls}>
                                <img
                                  class="d-block w-100"
                                  src={host + "uploads/" + slideplan}
                                  alt={slideplan}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <a
                          class="carousel-control-prev"
                          href={"#carouselPlansControlsmodal"}
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a
                          class="carousel-control-next"
                          href={"#carouselPlansControlsmodal"}
                          role="button"
                          data-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                      {/* -------------- */}
                    </div>
                  </div>
                </div>
              </div>
              {/* ---- fin modal slider plans 1 -- */}
              </div>
         
            </MediaQuery>

          </div>

        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default plans;
