import React, { Component } from "react";
import "../video.css";

export class Virtuelle extends Component {
  constructor(props) {
    super(props);

    this.project = {};
  }
  componentDidMount() {
    this.video = this.props.video;
  }
  render() {

    return (
      <div className="section section-padding">
        {" "}
        <div className="row">
          <div className="zone-title">
            <h2 className="title">Visite Virtuelle</h2>
          </div>
        </div>
        <div className="container">
          <div className="row video">
            <div className="col-md-4">
              <div className="all-centre">
                <h2 style={{ color: "#fff" }}>
                  <span>Vivez l'expérience</span>
                  <br /> En faisant une <br />
                  <span>Visite Virtuelle</span>
                </h2>
              </div>
            </div>
            <div className="col-md-8">
              <iframe title="Description of the iframe content"
                width="560"
                height="315"
                src="https://www.mkstudios.fr/magichousebeach/vr/VR_MHB.html"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Virtuelle;
