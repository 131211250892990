import React, { Component } from "react";
import Galerie from "./galerie";
import Projets from "./projets";
import Localisation from "./localisation";
import Acceuil from "./acceuil";
import Plans from "./plans";
import Header from "../websitelayout/Header";

import Description from "./decription";
import Video from "./video";
import Virtuelle from "./virtuelle";
import Atouts from "./atouts";
import image1 from "../../Images/image1.PNG";
import image2 from "../../Images/image2.PNG";
import image3 from "../../Images/image3.PNG";
export class index extends Component {
  render() {
    return (
      <div>
        <Header />
        <div
          className="container-fluid"
          style={{ backgroundColor: "white", padding: 0 }}
        >
          <Acceuil />
        </div>

        <div className="section-padding" style={{ backgroundColor: "white" }}>
          <br />
          <Description />
          <br />
        </div>
        <div
          className="section-padding Galerie"
          style={{ backgroundColor: "grey" }}
        >
          <div className="row">
            <div className="zone-title">
              <h2 className="title">Galerie</h2>
            </div>
          </div>
          <br />
          <Galerie />
          {/* ---- modal slider -- */}
          <div
            className="modal fade swiper-slider"
            id="swiper-slide1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  {/* ---- slider -- */}
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          className="d-block w-100"
                          src={image1}
                          alt="Los Angeles"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          className="d-block w-100"
                          src={image2}
                          alt="Los Angeles"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          className="d-block w-100"
                          src={image3}
                          alt="Los Angeles"
                        />
                      </div>
                    </div>
                    <a
                      className="carousel-control-prev"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                  {/* -------------- */}
                </div>
              </div>
            </div>
          </div>
          {/* ---- fin modal slider -- */}
          <br />
          <div className="text-center">
            <button className="btn">PLAN DE MASSE</button>
            <button className="btn" style={{ marginLeft: "10px" }}>
              ETAT D'AVANCEMENT
            </button>
          </div>
          <br />
          <br />
        </div>
        <div
          className="plans section-padding"
          style={{
            // backgroundImage: `url(${image})`,
            backgroundColor: "white"
          }}
        >
          <div className="row">
            <div className="zone-title">
              <h2 className="title">NOS PLANS</h2>
            </div>
          </div>
          <Plans />
        </div>
        <div className="video" style={{ backgroundColor: "white" }}>
          <Video />
        </div>

        <div
          className="section-padding atouts"
          style={{ backgroundColor: "white" }}
        >
          <br />
          <div className="row">
            <div className="zone-title">
              <h2 className="title">Nos Atouts</h2>
            </div>
          </div>
          <Atouts />
          <br />
        </div>

        <div className="virtu" style={{ backgroundColor: "white" }}>
          <Virtuelle />
        </div>
        <div className="section-padding maps" style={{ backgroundColor: "white" }}>
          <br />
          <div className="row">
            <div className="zone-title">
              <h2 className="title">NOUS SOMMES ICI</h2>
            </div>
          </div>
          <Localisation />
          <br />
        </div>
        <div
          className="section-padding projets"
          style={{ backgroundColor: "grey" }}
        >
          <div className="row">
            <div className="zone-title">
              <h2 className="title">Nos projets</h2>
            </div>
          </div>
          <br />
          <Projets />

          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default index;
