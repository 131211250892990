import React, { Component } from 'react'
import image from '../../Images/4-3.png'
import MediaQuery from "react-responsive";
import pdf from '../../Images/Chaabane-Immobilier.pdf'


export class notregroupe extends Component {
    render() {
        return (
            <div id="notregroupe-section" className="row align-items-center">

                <MediaQuery maxDeviceWidth={790}>
                    {/* mobile */}
                    <div id="vision-text" className="col-md-7">
                        <h1>NOTRE GROUPE </h1>
                        <h4>CHAABANE INVEST, L’HISTOIRE D’UNE VOCATION, D’UNE FAMILLE ET D’UNE FORMIDABLE AMBITION.</h4>
                        <p> L’histoire du groupe est liée à celle d’une famille, CHAABANE, qui lui a apporté ses ambitions et ses valeurs.
                             Son histoire est celle d’une succession d’exploits menés par une famille visionnaire et grâce à ses fondateurs réputés pour leur résilience.
                            Un chemin parcouru depuis 1995, lorsque M. Mohammed CHAABANE a créé son aﬀaire dans l’immobilier.
                            &laquo;De la petite entreprise CHAABANE IMPORT EXPORT BATIMENT (CIEB) au groupe d’aujourd’hui aux compétences aussi multiples que
                              complémentaires CHAABANE INVEST avec ses diﬀérentes ﬁliales CHAABANE IMMOBILIER anciennement nommée Chaabane lil iskane et 
                              CHAABANE DISTRUBUTION représenté par la société CIEB Maroc.&raquo;</p>
                    </div>
                    <div id="notregroupe-image" className="col-md-5">
                        <img src={image} alt="" />
                        <button className="btn"><a href={pdf}><i className="fa fa-download"></i>Brochure</a></button>
                    </div>
                </MediaQuery>
                <MediaQuery minDeviceWidth={790}>
                    {/* desktop */}
                    <div id="vision-text" className="col-md-7">
                        <h1>NOTRE GROUPE</h1>
                        <h4>CHAABANE INVEST, L’HISTOIRE D’UNE VOCATION, D’UNE FAMILLE ET D’UNE FORMIDABLE AMBITION.</h4>
                        <p> L’histoire du groupe est liée à celle d’une famille, CHAABANE, qui lui a apporté ses ambitions et ses valeurs.
                             Son histoire est celle d’une succession d’exploits menés par une famille visionnaire et grâce à ses fondateurs réputés pour leur résilience.
                            Un chemin parcouru depuis 1995, lorsque M. Mohammed CHAABANE a créé son aﬀaire dans l’immobilier.
                              De la petite entreprise CHAABANE IMPORT EXPORT BATIMENT (CIEB) au groupe d’aujourd’hui aux compétences aussi multiples que
                              complémentaires &laquo;CHAABANE INVEST&raquo; avec ses diﬀérentes ﬁliales CHAABANE IMMOBILIER anciennement nommée Chaabane lil iskane et 
                              CHAABANE DISTRUBUTION représenté par la société CIEB Maroc.</p>
                        <button className="btn"><a href={pdf}><i className="fa fa-download"></i>Brochure</a></button>
                    </div>
                    <div id="notregroupe-image" className="col-md-5">
                        <img src={image} alt="" />
                    </div>
                </MediaQuery>


            </div>
        )
    }
}

export default notregroupe
