import React, { Component } from "react";
import fullsize from "../../Images/full-size.png";
import "lightbox2/dist/js/lightbox.js";
import "lightbox2/dist/css/lightbox.css";
import host from "../../config/api";
import MediaQuery from "react-responsive";
import Carousel from "bee-carousel";

export class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: this.props.project || {}, // Default to an empty object if project is not provided
      plans: [], // Default to an empty array
      index: 0,
    };
  }

  componentDidUpdate(prevProps) {
    // Update state when props.project changes
    if (prevProps.project !== this.props.project) {
      this.setState({
        project: this.props.project || {},
        plans: this.props.project?.nosPlans || [],
      });
    }
  }

  changeArray(index) {
    const plans = this.state.project.nosPlans[index]?.image || [];
    this.setState({ plans });
  }

  render() {
    const { project } = this.state;

    const params = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      slidesPerView: 1.1,
      spaceBetween: 10,
    };

    if (!project.nosPlans) {
      return <div>No plans available</div>;
    }

    return (
      <div>
        <div style={{ padding: "15px 30px" }}>
          <MediaQuery maxDeviceWidth={790}>
            {/* Mobile */}
            <div className="mob_plans">
              <Carousel {...params}>
                {project.nosPlans.map((plan, index) => (
                  <div className="swiper-slide-plan" style={{ zIndex: "1050" }} key={index}>
                    <div className="">
                      <div
                        className="card bg-light border-primary"
                        style={{
                          border: "1px solid",
                          borderColor: "#fff!important",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          id={"carouselPlansControls" + index}
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <div className="carousel-inner">
                            {plan.image.length > 0 ? (
                              plan.image.map((img, imgIndex) => (
                                <div
                                  className={`carousel-item ${imgIndex === 0 ? 'active' : ''}`}
                                  key={imgIndex}
                                >
                                  <a href="#">
                                    <img src={`${host}uploads/${img}`} alt="" />
                                  </a>
                                </div>
                              ))
                            ) : (
                              <div className="carousel-item">
                                <p>No images available</p>
                              </div>
                            )}
                          </div>
                          <a
                            className="carousel-control-prev"
                            href={"#carouselPlansControls" + index}
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Previous</span>
                          </a>
                          <a
                            className="carousel-control-next"
                            href={"#carouselPlansControls" + index}
                            role="button"
                            data-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Next</span>
                          </a>
                          <a
                            className="fullsize"
                            href={`${host}uploads/${plan.image[0]}`}
                            data-toggle="modal"
                            data-target={"#swiper-slide-plan" + (index + 1)}
                          >
                            <img src={fullsize} alt="" onClick={() => this.changeArray(index)} />
                          </a>
                        </div>

                        <div className="card-body">
                          <h2
                            style={{
                              color: "#554f31",
                              textAlign: "left",
                              fontSize: "20px",
                              paddingLeft: "25px",
                            }}
                          >
                            {plan.title}
                          </h2>
                          <ul>
                            {plan.informations.length > 0 ? (
                              plan.informations.map((info, infoIndex) => (
                                <li key={infoIndex}>{info}</li>
                              ))
                            ) : (
                              <li>No information available</li>
                            )}
                          </ul>
                        </div>

                        {/* Modal Slider */}
                        <div
                          className="modal fade swiper-slider"
                          id={"swiper-slide-plan" + (index + 1)}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document" >
                            <div className="modal-content">
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                              <div className="modal-body">
                                {/* Slider */}
                                <div
                                  id={"carouselPlansControlsmodal" + (index + 1)}
                                  className="carousel slide"
                                  data-ride="carousel"
                                >
                                  <div className="carousel-inner">
                                    {plan.image.length > 0 ? (
                                      plan.image.map((slideplan, slideIndex) => (
                                        <div
                                          className={`carousel-item ${slideIndex === 0 ? 'active' : ''}`}
                                          key={slideIndex}
                                        >
                                          <img
                                            className="d-block w-100"
                                            src={`${host}uploads/${slideplan}`}
                                            alt={slideplan}
                                          />
                                        </div>
                                      ))
                                    ) : (
                                      <div className="carousel-item">
                                        <p>No images available</p>
                                      </div>
                                    )}
                                  </div>
                                  <a
                                    className="carousel-control-prev"
                                    href={"#carouselPlansControlsmodal" + (index + 1)}
                                    role="button"
                                    data-slide="prev"
                                  >
                                    <span
                                      className="carousel-control-prev-icon"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Previous</span>
                                  </a>
                                  <a
                                    className="carousel-control-next"
                                    href={"#carouselPlansControlsmodal" + (index + 1)}
                                    role="button"
                                    data-slide="next"
                                  >
                                    <span
                                      className="carousel-control-next-icon"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Next</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </MediaQuery>

          <MediaQuery minDeviceWidth={790}>
            <div className="row">
              {project.nosPlans.map((plan, index) => (
                <div className="col-4" key={index}>
                  <div
                    className="card bg-light border-primary"
                    style={{
                      border: "1px solid",
                      borderColor: "#fff!important",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      id={"carouselPlansControls" + index}
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner">
                        {plan.image.length > 0 ? (
                          plan.image.map((img, imgIndex) => (
                            <div
                              className={`carousel-item ${imgIndex === 0 ? 'active' : ''}`}
                              key={imgIndex}
                            >
                              <a href="#">
                                <img src={`${host}uploads/${img}`} alt="" />
                              </a>
                            </div>
                          ))
                        ) : (
                          <div className="carousel-item">
                            <p>No images available</p>
                          </div>
                        )}
                      </div>
                      <a
                        className="carousel-control-prev"
                        href={"#carouselPlansControls" + index}
                        role="button"
                        data-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="carousel-control-next"
                        href={"#carouselPlansControls" + index}
                        role="button"
                        data-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Next</span>
                      </a>
                      <a
                        className="fullsize"
                        href={`${host}uploads/${plan.image[0]}`}
                        data-toggle="modal"
                        data-target={"#swiper-slide-plan" + (index + 1)}
                      >
                        <img src={fullsize} alt="" onClick={() => this.changeArray(index)} />
                      </a>
                    </div>

                    <div className="card-body">
                      <h2
                        style={{
                          color: "#554f31",
                          textAlign: "left",
                          fontSize: "20px",
                          paddingLeft: "25px",
                        }}
                      >
                        {plan.title}
                      </h2>
                      <ul>
                        {plan.informations.length > 0 ? (
                          plan.informations.map((info, infoIndex) => (
                            <li key={infoIndex}>{info}</li>
                          ))
                        ) : (
                          <li>No information available</li>
                        )}
                      </ul>
                    </div>

                    {/* Modal Slider */}
                    <div
                      className="modal fade swiper-slider"
                      id={"swiper-slide-plan" + (index + 1)}
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document" >
                        <div className="modal-content">
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <div className="modal-body">
                            {/* Slider */}
                            <div
                              id={"carouselPlansControlsmodal" + (index + 1)}
                              className="carousel slide"
                              data-ride="carousel"
                            >
                              <div className="carousel-inner">
                                {plan.image.length > 0 ? (
                                  plan.image.map((slideplan, slideIndex) => (
                                    <div
                                      className={`carousel-item ${slideIndex === 0 ? 'active' : ''}`}
                                      key={slideIndex}
                                    >
                                      <img
                                        className="d-block w-100"
                                        src={`${host}uploads/${slideplan}`}
                                        alt={slideplan}
                                      />
                                    </div>
                                  ))
                                ) : (
                                  <div className="carousel-item">
                                    <p>No images available</p>
                                  </div>
                                )}
                              </div>
                              <a
                                className="carousel-control-prev"
                                href={"#carouselPlansControlsmodal" + (index + 1)}
                                role="button"
                                data-slide="prev"
                              >
                                <span
                                  className="carousel-control-prev-icon"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Previous</span>
                              </a>
                              <a
                                className="carousel-control-next"
                                href={"#carouselPlansControlsmodal" + (index + 1)}
                                role="button"
                                data-slide="next"
                              >
                                <span
                                  className="carousel-control-next-icon"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Next</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </MediaQuery>
        </div>
      </div>
    );
  }
}

export default Plans;
