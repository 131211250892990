import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import host from "./../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.css";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import Contactpopup from "./Contact-popup";

export class Header extends React.Component {
  render() {
    const { project, projects } = this.props;

    if (!project) {
      return null;
    }

    return (
      <div>
        <nav id="Header" className="navbar navbar-expand-lg">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item info">
              <FontAwesomeIcon icon={faPhoneAlt} />
              <a href="tel:+212522211717">+212 522 21 17 17</a> / <a href="tel:+212614989898">+212 614 98 98 98</a>
            </li>
            <li className="nav-item info">
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <a href="mailto:contact@chaabaneinvest.com">contact@chaabaneinvest.com</a>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <a className="social-media" target="_blank" rel="noopener noreferrer" href="https://fr-fr.facebook.com/Chaabaneimmobilier/">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a className="social-media" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/chaabane-immobilier">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a className="social-media" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/chaabane.immobilier/?hl=fr">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a className="social-media ml-1" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCUFlcKD70nQZj4s4Ky0yHHQ">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </ul>
        </nav>

        <nav id="Header2" className="navbar navbar-expand-lg">
          <img
            src={host + "uploads/" + project.logoP}
            className="d-block"
            alt="Logo"
          />
          <ul className="navbar-nav ml-auto">
            <li className="item">
              <Link className="link" to="/">ACCUEIL</Link>
            </li>
            <li className="item">
              <Link className="link" to="/Apropos">A PROPOS</Link>
            </li>
            <li className="item">
              <Link className="link" data-toggle="modal" data-target="#noprojet">NOS PROJETS</Link>
            </li>
            <li className="item">
              <span className="contact-popup-btn">NOUS CONTACTER</span>
            </li>
          </ul>
        </nav>

        {(project.logoP && project.title && projects) && (
          <div className="contact-popup hidden">
            <Contactpopup project={project} projects={projects} />
          </div>
        )}
      </div>
    );
  }
}

export default Header;
